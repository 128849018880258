import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useContractList = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [contractList, setContractList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContractList = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        API_URL.CONTRACT_LIST,
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setContractList(res.content);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setContractList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [contractList, isLoading, fetchContractList],
    [contractList, isLoading, fetchContractList]
  );
};

export default useContractList;
