import useContractList from "../../lib/api/useContractList";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addMonthTimestamp } from "../../lib/tools";
import { TextButton } from "../../components/Button";
import {
  CONTRACT_PLAN_OPTIONS,
  CONTRACT_STATUS_OPTIONS,
  CONTRACT_SOURCE_OPTIONS,
} from "../../config/enum";
import { FilterBarStyled } from "../../components/FilterBar";
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  ZoomiDataGrid,
  ZoomiPagination,
  CustomToolbar,
} from "../../components/DataGrid";
import { calcDateDiff } from "../../lib/tools";
import {
  REVIEW_STATUS_OPTIONS,
  PROGRESS_STATUS_OPTIONS,
} from "../../config/enum";
import {
  getContractStatusText,
  getContractProgressText,
} from "../../config/setting";

// 合約管理
const BidList = () => {
  const [contractList, isLoading, fetchContractList] = useContractList();
  const [filterParams, setFilterParams] = useState({
    createMillisDesc: true,
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchContractList(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  const handleSelectedContract = (contract) => {
    navigate("/bid-list/verification/" + contract.recordId, {
      state: { contract },
    });
  };

  const handleFilterParamsChange = (key, value) => {
    if (value === "ALL" || value[0] === "ALL") {
      value = null;
    }

    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdatecontractList = () => {
    fetchContractList(filterParams);
  };

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  const columns = [
    { field: "email", headerName: "帳號", minWidth: 200, flex: 2 },
    { field: "userName", headerName: "姓名", minWidth: 80, flex: 1 },
    {
      field: "originalContractName",
      headerName: "方案",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "originalPhoneType",
      headerName: "手機型號",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>{`${params.row.originalPhoneType.phoneName}(${params.row.originalPhoneType.phoneColor}/${params.row.originalPhoneType.phoneCapacity})`}</div>
      ),
    },
    {
      field: "createSource",
      headerName: "來源",
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        <div>{CONTRACT_SOURCE_OPTIONS.get(params.row.createSource)?.label}</div>
      ),
    },
    {
      field: "status",
      headerName: "審核狀態",
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        <div>{getContractStatusText(params.row.status)}</div>
      ),
    },
    {
      field: "statusProgress",
      headerName: "進度",
      minWidth: 70,
      flex: 1,
      renderCell: (params) => (
        <div>{getContractProgressText(params.row.status) || "-"}</div>
      ),
    },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <>
          {
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleSelectedContract(params.row)}
            >
              {params.row.status === "IN_REVIEW" ? "審核" : "查看"}
            </TextButton>
          }
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        申辦列表
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="帳號搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("email", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="姓名搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("name", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          select
          label="狀態"
          size="small"
          sx={{ minWidth: "8rem" }}
          defaultValue={REVIEW_STATUS_OPTIONS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("statusList", e.target.value)
          }
        >
          {Array.from(REVIEW_STATUS_OPTIONS.keys()).map((key, index) => (
            <MenuItem key={index} value={REVIEW_STATUS_OPTIONS.get(key).value}>
              {REVIEW_STATUS_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="進度"
          size="small"
          sx={{ minWidth: "8rem" }}
          defaultValue={PROGRESS_STATUS_OPTIONS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("statusList", e.target.value)
          }
        >
          {Array.from(PROGRESS_STATUS_OPTIONS.keys()).map((key, index) => (
            <MenuItem
              key={index}
              value={PROGRESS_STATUS_OPTIONS.get(key).value}
            >
              {PROGRESS_STATUS_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleUpdatecontractList()}
        >
          查詢
        </TextButton>
      </FilterBarStyled>

      {contractList ? (
        <ZoomiDataGrid
          getRowId={(row) => row.recordId}
          rows={contractList ? contractList.userContracts : []}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          disableColumnFilter
          autoHeight
          sx={{ "--DataGrid-overlayHeight": "300px" }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={contractList?.totalPage}
      />
    </div>
  );
};

export default BidList;
