import { useEffect, useState } from "react";
import useFaqList from "../../lib/api/useFaqList";
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Switch,
} from "@mui/material";
import { TextButton } from "../../components/Button";
import { ZoomiDataGrid, ZoomiPagination } from "../../components/DataGrid";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";
import { NEWS_STATUS } from "../../config/enum";
import { FilterBarStyled } from "../../components/FilterBar";
import { useNavigate } from "react-router-dom";
import useFaqModify from "../../lib/api/useFaqModify";
import {
  ZoomiDialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentActionStyled,
} from "../../components/Dialog";

const FaqList = () => {
  const [faqList, faqListLoading, fetchFaqList] = useFaqList();
  const [faqListParams, setFaqListParams] = useState({
    createMillisDesc: true,
    statusList: ["ENABLE", "DISABLE"],
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const [faqModify, faqModifyLoading, requestFaqModify] = useFaqModify();
  const navigate = useNavigate();
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    fetchFaqList(faqListParams);
  }, [faqListParams.page, faqListParams.pageSize]);

  useEffect(() => {
    if (faqModify) {
      fetchFaqList(faqListParams);
    }
  }, [faqModify]);

  const handleSelectedPage = (event, value) => {
    setFaqListParams({ ...faqListParams, page: value - 1 });
  };

  const handleEditFaq = (actionType, faq) => {
    navigate("/faq-list/" + actionType + (faq ? "/" + faq.id : ""));
  };

  const handleDeleteFaq = (faq, onClose) => {
    requestFaqModify({
      id: faq.id,
      title: faq.title,
      content: faq.content,
      status: "DELETE",
    });
    onClose();
  };

  const handleSwitchFaqStatus = (checked, faq) => {
    let newFaq = {
      id: faq.id,
      title: faq.title,
      content: faq.content,
    };

    if (checked) {
      requestFaqModify({
        ...newFaq,
        status: "ENABLE",
      });
    } else {
      requestFaqModify({
        ...newFaq,
        status: "DISABLE",
      });
    }
  };

  const handleParamsChange = (key, value) => {
    setFaqListParams({ ...faqListParams, [key]: value });
  };

  const dataGridColumns = [
    { field: "title", headerName: "標題", minWidth: 200, flex: 1.5 },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1.5,
      minWidth: 200,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "updateMillis",
      headerName: "更新時間",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.updateMillis
            ? new Date(params.row.updateMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <>
          <Switch
            disabled={faqModifyLoading}
            defaultChecked={params.row.status === "ENABLE"}
            onChange={(e) =>
              handleSwitchFaqStatus(e.target.checked, params.row)
            }
          />
        </>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{
              marginRight: "10px",
            }}
            onClick={() => handleEditFaq("edit", params.row)}
          >
            編輯
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            loading={!!faqModifyLoading}
            onClick={() => {
              setSelectedFaq(params.row);
              setShowDeleteDialog(true);
            }}
          >
            刪除
          </TextButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        常見問題
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="標題搜尋"
          sx={{ minWidth: "10rem" }}
          variant="outlined"
          size="small"
          onChange={(e) => handleParamsChange("title", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          color="primary"
          select
          label="狀態"
          sx={{ minWidth: "10rem" }}
          size="small"
          defaultValue={NEWS_STATUS.get("ALL").value}
          onChange={(e) => handleParamsChange("statusList", e.target.value)}
        >
          {Array.from(NEWS_STATUS.keys()).map((key) => (
            <MenuItem key={key} value={NEWS_STATUS.get(key).value}>
              {NEWS_STATUS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => fetchFaqList(faqListParams)}
        >
          查詢
        </TextButton>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          sx={{ marginLeft: "auto" }}
          onClick={() => handleEditFaq("create")}
        >
          新增
        </TextButton>
      </FilterBarStyled>

      {faqList ? (
        <>
          <ZoomiDataGrid
            getRowId={(row) => row.id}
            columns={dataGridColumns}
            loading={!!faqListLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            autoHeight
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            rows={faqList ? faqList.faqDtos : []}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={faqList?.totalPage}
      />
      <ZoomiDialogStyled open={showDeleteDialog}>
        <DialogTitleStyled color="primary" variant="h4">
          刪除常見問題
        </DialogTitleStyled>
        <DialogContentStyled>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            資料刪除後不可復原，請問是否刪除常見問題？
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            問題：{selectedFaq?.title}
          </Typography>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowDeleteDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleDeleteFaq(selectedFaq, () => setShowDeleteDialog(false))
            }
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default FaqList;
