import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";

const usePhoneTypeList = () => {
  const [handleError] = useErrorHandler();
  const [phoneTypeList, setPhoneTypeList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPhoneTypeList = useCallback(async () => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/constant/phone-type/list",
        API_METHOD.GET,
        auth
      );
      if (res.message === "Success") {
        setPhoneTypeList(res.content.phoneTypes);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setPhoneTypeList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [phoneTypeList, isLoading, fetchPhoneTypeList],
    [phoneTypeList, isLoading, fetchPhoneTypeList]
  );
};

export default usePhoneTypeList;
