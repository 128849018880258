import { styled } from "@mui/material/styles";

export const drawerWidth = 180;
export const headerHeight = "50px";

// export const ContainerStyled = styled("main", {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: "20px",
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   }),
// }));
export const RootLayoutStyled = styled("div")``;
export const ContainerStyled = styled("div")`
  position: absolute;
  top: ${headerHeight};
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;

  .inner {
  }
`;

export const ContentStyled = styled("div")`
  margin: 2rem;
  transition: all 0.2s ease-out;
  box-sizing: border-box;
  ${({ open }) => open && `padding-left: ${drawerWidth}px;`}
`;

export const HeaderStyled = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: 0 2rem;
`;

export const HeaderTitleStyled = styled("div")`
  display: flex;
  align-items: center;
`;

export const FooterStyled = styled("div")``;
