import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Table,
  TableContainer,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
  TextField,
  MenuItem,
  Pagination,
  IconButton,
} from "@mui/material";
import {
  MemberDetailStyled,
  MemberDetailInformationStyled,
  MemberDetailInformationBlockStyled,
  MemberDetailInformationBlockTitleStyled,
  MemberDetailInformationBlockContentStyled,
} from "./styled";
import { formatMoney } from "../../../lib/tools";
import { ZoomiDataGrid, ZoomiPagination } from "../../../components/DataGrid";
import {
  InformationCardStyled,
  InformationBlockTitleStyled,
  InformationBlockContentStyled,
  InformationBlockStyled,
} from "../../../components/Card";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation } from "react-router";
import useContractList from "../../../lib/api/useContractList";
import { MEMBER_KYC_STATUS, GENDER_OPTIONS } from "../../../config/enum";
import { PAGE_SIZE, PAGE_START } from "../../../config/setting";
import { formatTimestamp } from "../../../lib/tools";
import { ContractStatus, DEFAULT_STATUS_LIST } from "../../../config/setting";

const detailConfig = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "帳號",
    key: "email",
  },
  {
    title: "姓名",
    key: "name",
  },
  {
    title: "註冊時間",
    key: "createMillis",
    renderCell: (item) => {
      return (
        <>
          {item.createMillis
            ? new Date(item.createMillis).toLocaleString()
            : ""}
        </>
      );
    },
  },
  {
    title: "性別",
    key: "gender",
    renderCell: (item) => {
      return <>{GENDER_OPTIONS.get(item.gender)?.label}</>;
    },
  },
  {
    title: "出生日期",
    key: "birthdayStr",
  },
  {
    title: "身分證字號",
    key: "idNumber",
  },
  {
    title: "職業",
    key: "jobName",
  },
  {
    title: "手機號碼",
    key: "phoneNumber",
  },
  {
    title: "通訊地址",
    key: "address",
    renderCell: (item) => {
      return (
        <>{item.postalCode + item.cityName + item.townName + item.address}</>
      );
    },
  },
];

const dataGridColumns = [
  {
    field: "contractStartMillis",
    headerName: "合約日期",
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <p>
          {params.row.contractStartMillis
            ? formatTimestamp(params.row.contractStartMillis)
            : ""}
        </p>

        <p>
          {params.row.contractEndMillis
            ? "~" + formatTimestamp(params.row.contractEndMillis)
            : ""}
        </p>
      </div>
    ),
  },
  { field: "contractName", headerName: "方案", minWidth: 100, flex: 0.8 },
  {
    field: "phoneType",
    headerName: "手機型號",
    flex: 1,
    minWidth: 180,
    renderCell: (params) => (
      <>
        {`${params.row.phoneType.phoneName} (${params.row.phoneType.phoneCapacity} ${params.row.phoneType.phoneColor})`}
      </>
    ),
  },
  { field: "phoneImei", headerName: "IMEI", minWidth: 100, flex: 1 },
  {
    field: "monthlyPrice",
    headerName: "月租費",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => <>{formatMoney(params.row.monthlyPrice, 2)}</>,
  },
  {
    field: "status",
    headerName: "合約狀態",
    flex: 0.8,
    minWidth: 80,
    renderCell: (params) => <div>{ContractStatus(params.row).label}</div>,
  },
  {
    field: "createMillis",
    headerName: "建立時間",
    flex: 1.5,
    minWidth: 180,
    renderCell: (params) => (
      <div>
        {params.row.createMillis
          ? new Date(params.row.createMillis).toLocaleString()
          : ""}
      </div>
    ),
  },
];

const MemberDetail = () => {
  const location = useLocation();
  let { member } = location.state;
  // const [memberDetail, isLoading, fetchMemberDetail] = useMemberDetail(uid);
  const [params, setParams] = useState({
    page: PAGE_START,
    size: PAGE_SIZE,
    userId: member.id,
    statusList: DEFAULT_STATUS_LIST,
  });
  const [contractList, contractListLoading, fetchContractList] =
    useContractList();
  const navigate = useNavigate();

  useEffect(() => {
    if (!member) {
      handleGoBack();
    }
    fetchContractList(params);
  }, [params.page, params.pageSize]);

  const handleSelectedPage = (event, value) => {
    setParams({ ...params, page: value - 1 });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <MemberDetailStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        會員詳情
      </Typography>

      <MemberDetailInformationStyled>
        <InformationCardStyled variant="outlined" color="primary">
          <Typography
            color="primary"
            variant="h4"
            align="left"
            sx={{ width: "100%", marginBottom: "1rem" }}
          >
            會員資料
          </Typography>
          {detailConfig.map((item, index) => {
            return (
              <div key={index}>
                <InformationBlockStyled>
                  <InformationBlockTitleStyled>
                    {item.title}
                  </InformationBlockTitleStyled>
                  <InformationBlockContentStyled>
                    {item.renderCell && item.key
                      ? item.renderCell(member)
                      : member[item.key]}
                  </InformationBlockContentStyled>
                </InformationBlockStyled>
              </div>
            );
          })}
        </InformationCardStyled>
      </MemberDetailInformationStyled>
      {contractList ? (
        <>
          <Typography color="primary" variant="h4" align="left">
            合約資料
          </Typography>
          <ZoomiDataGrid
            getRowId={(row) => row.recordId}
            columns={dataGridColumns}
            loading={contractListLoading}
            rows={contractList?.userContracts}
            autoHeight
            sx={{ "--DataGrid-overlayHeight": "300px" }}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={contractList?.totalPage}
      />
    </MemberDetailStyled>
  );
};

export default MemberDetail;
