export const formatMoney = (price, decimal) => {
  if (typeof price !== "number") {
    return "";
  }

  return Intl.NumberFormat("en-US", { decimal }).format(price);
};

// //  將輸入的時間戳去掉當前時區
// export const calcTimestampUTC = (timestamp) => {
//   // 取得當前時間戳偏差（以毫秒為單位）
//   const timeZoneOffsetInMinutes = new Date().getTimezoneOffset();

//   return timestamp + timeZoneOffsetInMinutes * 60 * 1000;
// };

// 將輸入的時間只顯示年月日 ex: 2021/01/01
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month}/${day}`;
};

// 將輸入的時間 ex: yyyy-MM-ddThh:mm
export const formatTimestampToInput = (timestamp) => {
  const date = new Date(timestamp);
  var year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要加1，并确保两位数
  var day = String(date.getDate()).padStart(2, "0");
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0");

  // 创建格式化的日期时间字符串
  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
};

// 將輸入的時間戳加上月數
export const addMonthTimestamp = (timestamp, month) => {
  const date = new Date(timestamp);
  date.setMonth(date.getMonth() + month);
  return date;
};

// 計算兩個時間戳相差天數
export const calcDateDiff = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// 檢查必填欄位
export const checkRequireParams = (params, setError) => {
  let pass = true;
  let newError = { ...params };

  for (let key in params) {
    if (params[key] === null || params[key] === "") {
      pass = false;
      newError[key] = "此欄位為必填";
    } else {
      newError[key] = null;
    }
  }
  setError(newError);
  return pass;
};

export const handleChangeParams = (
  key,
  value,
  error,
  setError,
  params,
  setParams
) => {
  if (error[key]) {
    setError({ ...error, [key]: null });
  }
  setParams({ ...params, [key]: value });
};
