export const API_URL = {
  LOGIN: "/zoomi/admin/login",
  LOGOUT: "/zoomi/admin/logout",
  CONTRACT_LIST: "/zoomi/admin/contract/list",
  USER_INFO_LIST: "/zoomi/admin/user/info/list",
  USER_KYC_LIST: "/zoomi/admin/user/kyc/list",
  USER_KYC_IMAGE: "/zoomi/admin/user/kyc/img/get",
  USER_KYC_APPROVAL: "/zoomi/admin/user/kyc/approval",
};

export const API_METHOD = {
  POST: "POST",
  GET: "GET",
};

export const requestHandler = async (url, method, authToken, other) => {
  // try {
  //   const res = await fetch("/uc/login", {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify({
  //       username: "jean@bitstreetx.com",
  //       password: "123456",
  //     }),
  //     method: "POST",
  //     redirect: "follow",
  //   }); // 使用代理設定的路徑

  //   const resJson = await res.json();

  //   return resJson;
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   throw error;
  // }

  try {
    const res = await fetch(url, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "ADMIN-LOGIN-TOKEN": authToken || "",
      },
      method,
      redirect: "follow",
      ...other,
    }); // 使用代理設定的路徑

    const resJson = await res.json();

    return resJson;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
