import { styled, Typography, ListItemButton, List } from "@mui/material";
export const LinkOptionStyled = styled(ListItemButton)`
  cursor: pointer;
  padding-left: 1rem;
  & a {
    color: ${({ theme }) => theme.palette.text.primary};
    :hover,
    :visited,
    :link,
    :active {
      text-decoration: none;
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const LinkText = styled(Typography)`
  ${({ active, theme }) =>
    active &&
    `
      color: ${theme.palette.text.secondary};
    `}
`;

export const MenuStyled = styled(List)`
  padding: 1rem;
`;

export const MenuCollapseContentStyled = styled("div")`
  padding-left: 1rem;
`;
