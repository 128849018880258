import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import MemberManagement from "./pages/MemberManagement";
import ContractManagement from "./pages/ContractManagement";
import ContractDetail from "./pages/ContractManagement/ContractDetail";
import MemberVerification from "./pages/MemberVerification";
import BidList from "./pages/BidList";
import Login from "./pages/Login";
import MemberDetail from "./pages/MemberManagement/MemberDetail";
import MemberApplication from "./pages/MemberManagement/MemberApplication";
import MemberReview from "./pages/MemberVerification/MemberReview";
import BidVerification from "./pages/BidList/BidVerification";
import BillList from "./pages/BillList";
import StoreManagement from "./pages/StoreManagement";
import StoreModify from "./pages/StoreManagement/StoreModify";
import UserManagement from "./pages/UserManagement";
import UserModify from "./pages/UserManagement/UserModify";
import ProductManagement from "./pages/ProductManagement";
import ProductModify from "./pages/ProductManagement/ProductModify";
import ProductCreate from "./pages/ProductManagement/ProductCreate";
import NewsList from "./pages/NewsList";
import NewsEdit from "./pages/NewsList/NewsEdit";
import FaqList from "./pages/FaqList";
import FaqEdit from "./pages/FaqList/FaqEdit";
import UserCenter from "./pages/UserCenter";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<RootLayout />}>
          <Route path="contract-management" element={<ContractManagement />} />
          <Route
            path="contract-management/:recordId"
            element={<ContractDetail />}
          />
          <Route path="member-management" element={<MemberManagement />} />
          <Route path="member-management/:uid" element={<MemberDetail />} />
          <Route
            path="member-management/application"
            element={<MemberApplication />}
          />
          <Route path="member-verification" element={<MemberVerification />} />
          <Route path="member-verification/:id" element={<MemberReview />} />
          <Route path="bid-list" element={<BidList />} />
          <Route
            path="bid-list/verification/:recordId"
            element={<BidVerification />}
          />
          <Route path="bill-list" element={<BillList />} />
          <Route path="store-management" element={<StoreManagement />} />
          <Route path="store-management/modify" element={<StoreModify />} />
          <Route path="user-management" element={<UserManagement />} />
          <Route path="user-management/modify" element={<UserModify />} />
          <Route path="product-management" element={<ProductManagement />} />
          <Route path="product-management/modify" element={<ProductModify />} />
          <Route path="product-management/create" element={<ProductCreate />} />
          <Route path="store-management/modify" element={<StoreModify />} />

          <Route path="news-list" element={<NewsList />} />
          <Route path="news-list/:status" element={<NewsEdit />} />
          <Route path="news-list/:status/:id" element={<NewsEdit />} />

          <Route path="faq-list" element={<FaqList />} />
          <Route path="faq-list/:status" element={<FaqEdit />} />
          <Route path="faq-list/:status/:id" element={<FaqEdit />} />

          <Route path="user-center" element={<UserCenter />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
