import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";

const useNewsList = () => {
  const [handleError] = useErrorHandler();
  const [newsList, setNewsList] = useState(null);
  const [newsListLoading, setIsLoading] = useState(false);

  const fetchNewsList = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/news/list",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setNewsList(res.content);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setNewsList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [newsList, newsListLoading, fetchNewsList],
    [newsList, newsListLoading, fetchNewsList]
  );
};

export default useNewsList;
