import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

/**
 * 取得BillList假資料 return promise
 * 範例:
 * {
  "code": "<integer>",
  "message": "<string>",
  "content": {
    "totalPage": "<integer>",
    "totalElement": "<long>",
    "bills": [
      {
        "recordId": "<string>",
        "userRecordId": "<string>",
        "email": "<string>",
        "yearMonth": "<string>",
        "totalAmount": "<number>",
        "contractAmount": "<number>",
        "damagesAmount": "<number>",
        "discountAmount": "<number>",
        "day": "<integer>",
        "startMillis": "<long>",
        "endMillis": "<long>",
        "payType": "STORE_PAYMENT",
        "status": "PAYMENT_CONFIRMING",
        "isOverdue": "<boolean>",
        "bankName": "<string>",
        "bankAccountName": "<string>",
        "bankAccount": "<string>",
        "paymentDeadlineMillis": "<long>",
        "createMillis": "<long>",
        "paymentMillis": "<long>",
        "billDetails": [
          {
            "userContractId": "<long>",
            "userContractRecordId": "<string>",
            "amount": "<number>",
            "day": "<integer>",
            "startMillis": "<long>",
            "endMillis": "<long>",
            "createMillis": "<long>"
          },
          {
            "userContractId": "<long>",
            "userContractRecordId": "<string>",
            "amount": "<number>",
            "day": "<integer>",
            "startMillis": "<long>",
            "endMillis": "<long>",
            "createMillis": "<long>"
          }
        ]
      },
      {
        "recordId": "<string>",
        "userRecordId": "<string>",
        "email": "<string>",
        "yearMonth": "<string>",
        "totalAmount": "<number>",
        "contractAmount": "<number>",
        "damagesAmount": "<number>",
        "discountAmount": "<number>",
        "day": "<integer>",
        "startMillis": "<long>",
        "endMillis": "<long>",
        "payType": "STORE_PAYMENT",
        "status": "PAYMENT_COMPLETED",
        "isOverdue": "<boolean>",
        "bankName": "<string>",
        "bankAccountName": "<string>",
        "bankAccount": "<string>",
        "paymentDeadlineMillis": "<long>",
        "createMillis": "<long>",
        "paymentMillis": "<long>",
        "billDetails": [
          {
            "userContractId": "<long>",
            "userContractRecordId": "<string>",
            "amount": "<number>",
            "day": "<integer>",
            "startMillis": "<long>",
            "endMillis": "<long>",
            "createMillis": "<long>"
          },
          {
            "userContractId": "<long>",
            "userContractRecordId": "<string>",
            "amount": "<number>",
            "day": "<integer>",
            "startMillis": "<long>",
            "endMillis": "<long>",
            "createMillis": "<long>"
          }
        ]
      }
    ]
  }
}
 */
// const getBillList = () => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({
//         code: 200,
//         message: "Success",
//         content: {
//           totalPage: 1,
//           totalElement: 2,
//           bills: [
//             {
//               recordId: "1",
//               userRecordId: "UC20230903nN0AXZ",
//               email: "1",
//               yearMonth: "1",
//               totalAmount: 1,
//               contractAmount: 1,
//               damagesAmount: 1,
//               discountAmount: 1,
//               day: 1,
//               startMillis: 1,
//               endMillis: 1,
//               payType: "STORE_PAYMENT",
//               status: "PAYMENT_CONFIRMING",
//               isOverdue: false,
//               bankName: "1",
//               bankAccountName: "1",
//               bankAccount: "1",
//               paymentDeadlineMillis: 1,
//               createMillis: 1,
//               paymentMillis: 1,
//               billDetails: [
//                 {
//                   userContractId: 1,
//                   userContractRecordId: "1",
//                   amount: 1,
//                   day: 1,
//                   startMillis: 1,
//                   endMillis: 1,
//                   createMillis: 1,
//                 },
//                 {
//                   userContractId: 1,
//                   userContractRecordId: "1",
//                   amount: 1,
//                   day: 1,
//                   startMillis: 1,
//                   endMillis: 1,
//                   createMillis: 1,
//                 },
//               ],
//             },
//             {
//               recordId: "2",
//               userRecordId: "UC20230903nN0AXZ",
//               email: "2",
//               yearMonth: "2",
//               totalAmount: 2,
//               contractAmount: 2,
//               damagesAmount: 2,
//               discountAmount: 2,
//               day: 2,
//               startMillis: 2,
//               endMillis: 2,
//               payType: "REMITTANCE",
//               status: "PAYMENT_COMPLETED",
//               isOverdue: false,
//               bankName: "2",
//               bankAccountName: "2",
//               bankAccount: "2",
//               paymentDeadlineMillis: 2,
//               createMillis: 2,
//               paymentMillis: 2,
//               billDetails: [
//                 {
//                   userContractId: 2,
//                   userContractRecordId: "2",
//                   amount: 2,
//                   day: 2,
//                   startMillis: 2,
//                   endMillis: 2,
//                   createMillis: 2,
//                 },
//                 {
//                   userContractId: 2,
//                   userContractRecordId: "2",
//                   amount: 2,
//                   day: 2,
//                   startMillis: 2,
//                   endMillis: 2,
//                   createMillis: 2,
//                 },
//               ],
//             },
//           ],
//         },
//       });
//     }, 1000);
//   });
// };

const useBillList = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [billList, setBillList] = useState(null);
  const [billListLoading, setIsLoading] = useState(false);

  const fetchBillList = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      // const res = await requestHandler(
      //   "/zoomi/admin/bill/list",
      //   API_METHOD.POST,
      //   auth,
      //   {
      //     body: JSON.stringify(filterParams),
      //   }
      // );

      const res = await requestHandler(
        "/zoomi/admin/bill/list",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );

      // const res = await getBillList();
      if (res.message === "Success") {
        setBillList(res.content);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setBillList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [billList, billListLoading, fetchBillList],
    [billList, billListLoading, fetchBillList]
  );
};

export default useBillList;
