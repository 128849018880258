import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useContractFormation = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [contractFormation, setContractFormation] = useState(null);
  const [contractFormationLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestContractFormation = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/contract/formation",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(param),
        }
      );
      if (res.message === "Success") {
        setContractFormation(res);
        enqueueSnackbar("成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setContractFormation(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [
      contractFormation,
      contractFormationLoading,
      requestContractFormation,
    ],
    [contractFormation, contractFormationLoading, requestContractFormation]
  );
};

export default useContractFormation;
