import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";

const useFaqCreate = () => {
  const [handleError] = useErrorHandler();
  const [faqCreate, setFaqCreate] = useState(null);
  const [faqCreateLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestFaqCreate = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/faq/create",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(param),
        }
      );
      if (res.message === "Success") {
        setFaqCreate(res);
        enqueueSnackbar("成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setFaqCreate(null);
      console.error("Error fetching data:", error);
      enqueueSnackbar("失敗", { variant: MESSAGE_TYPE.ERROR });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [faqCreate, faqCreateLoading, requestFaqCreate],
    [faqCreate, faqCreateLoading, requestFaqCreate]
  );
};

export default useFaqCreate;
