import { useState, useEffect } from "react";
import { FilterBarStyled } from "../../components/FilterBar";
import { TextField, MenuItem, Typography, DialogContent } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  BILL_PAYMENT_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "../../config/enum";
import { PAGE_SIZE, PAGE_START } from "../../config/setting";
import useBillList from "../../lib/api/useBillList";
import useBillConfirm from "../../lib/api/useBillConfirm";
import { TextButton } from "../../components/Button";
import {
  ZoomiDataGrid,
  ZoomiPagination,
  CustomToolbar,
} from "../../components/DataGrid";
import { formatMoney } from "../../lib/tools";
import { getBillPaymentStatus } from "../../config/setting";
import { Box, CircularProgress } from "@mui/material";
import useStorefront from "../../lib/api/useStorefrontList";
import {
  DialogContentActionStyled,
  ZoomiDialogStyled,
} from "../../components/Dialog";
import { BillContentItemRowStyled } from "./styled";

const BillList = () => {
  const [billList, billListLoading, fetchBillList] = useBillList();
  const [billConfirm, billConfirmLoading, requestBillConfirm] =
    useBillConfirm();
  const navigate = useNavigate();
  const [billListParams, setBillListParams] = useState({
    createMillisDesc: true,
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const [storefronts, isLoading, fetchStorefront] = useStorefront();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showBillDetailDialog, setShowBillDetailDialog] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);

  const handleSelectedContract = (contract) => {
    navigate("/contract-management/" + contract.userRecordId);
  };

  useEffect(() => {
    fetchBillList(billListParams);
  }, [billListParams.page, billListParams.pageSize]);

  useEffect(() => {
    if (billConfirm) {
      fetchBillList(billListParams);
    }
  }, [billConfirm]);

  const handleSelectedPage = (event, value) => {
    setBillListParams({ ...billListParams, page: value - 1 });
  };

  const handleBillConfirm = (recordId, onClose) => {
    requestBillConfirm({ recordId });
    onClose();
  };

  const handleParamsChange = (key, value) => {
    if (key === "statusList" && value[0] === "ALL") {
      value = [];
      setBillListParams({ ...billListParams, [key]: value });
    }

    setBillListParams({ ...billListParams, [key]: value });
  };

  const getStorefrontsName = (storefronts, storefrontId) => {
    const storefront = storefronts.find(
      (storefront) => storefront.id === storefrontId
    );
    return storefront ? storefront.name : "";
  };

  const handleSelectedBill = (bill, onOpen) => {
    setSelectedBill(bill);
    onOpen();
  };

  //取銀行帳號後五碼
  const getBankAccountLastFive = (bankAccount) => {
    return bankAccount ? bankAccount.slice(-5) : "";
  };

  const dataGridColumns = [
    {
      field: "userId",
      headerName: "ID",
      flex: 0.5,
      minWidth: 70,
    },
    {
      field: "email",
      headerName: "會員帳號",
      flex: 1.5,
      minWidth: 200,
    },
    {
      field: "recordId",
      headerName: "繳費單號",
      flex: 1.5,
      minWidth: 150,
    },
    { field: "yearMonth", headerName: "月份", minWidth: 80, flex: 1 },
    {
      field: "totalAmount",
      headerName: "費用",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => <>{formatMoney(params.row.totalAmount, 2)}</>,
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => <>{getBillPaymentStatus(params.row)?.label}</>,
    },
    {
      field: "paymentMillis",
      headerName: "繳費日期",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.paymentMillis
            ? new Date(params.row.paymentMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "payType",
      headerName: "繳費方式",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <>{PAYMENT_TYPE_OPTIONS.get(params.row.payType)?.label}</>
      ),
    },
    {
      field: "userBankAccount",
      headerName: "繳費資訊",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <>{getBankAccountLastFive(params.row.userBankAccount)}</>
      ),
    },
    {
      field: "remark",
      headerName: "備註",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <>{params.row.isOverdue && "逾期"}</>,
    },
    {
      field: "action",
      headerName: "操作",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginRight: "0.5rem" }}
            onClick={() =>
              handleSelectedBill(params.row, () =>
                setShowBillDetailDialog(true)
              )
            }
          >
            明細
          </TextButton>
          {getBillPaymentStatus(params.row)?.label === "待確認繳款" && (
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                handleSelectedBill(params.row, () => setShowConfirmDialog(true))
              }
            >
              繳費確認
            </TextButton>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        帳單列表
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="會員帳號搜尋"
          sx={{ minWidth: "10rem" }}
          variant="outlined"
          size="small"
          onChange={(e) => handleParamsChange("email", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          color="primary"
          select
          label="繳費"
          sx={{ minWidth: "10rem" }}
          size="small"
          defaultValue={BILL_PAYMENT_STATUS_OPTIONS.get("ALL").value}
          onChange={(e) => handleParamsChange("statusList", e.target.value)}
        >
          {Array.from(BILL_PAYMENT_STATUS_OPTIONS.keys()).map((key) => (
            <MenuItem
              key={key}
              value={BILL_PAYMENT_STATUS_OPTIONS.get(key).value}
            >
              {BILL_PAYMENT_STATUS_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => fetchBillList(billListParams)}
        >
          查詢
        </TextButton>
      </FilterBarStyled>
      {billList ? (
        <>
          <ZoomiDataGrid
            getRowId={(row) => row.recordId}
            columns={dataGridColumns}
            loading={billListLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            autoHeight
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            rows={billList ? billList.bills : []}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={billList?.totalPage}
      />

      <ZoomiDialogStyled minWidth={"18rem"} open={showConfirmDialog}>
        {getBillPaymentStatus(selectedBill)?.value === "PAYMENT_CONFIRMING" && (
          <Typography color="primary" variant="h4">
            "繳費確認"
          </Typography>
        )}

        <DialogContent>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            銀行代碼: {selectedBill?.userBankCode + selectedBill?.userBankName}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            帳號: {selectedBill?.userBankAccount}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            金額: {formatMoney(selectedBill?.totalAmount)}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            狀態: {getBillPaymentStatus(selectedBill)?.label}
          </Typography>
        </DialogContent>
        {getBillPaymentStatus(selectedBill)?.value !== "PAYMENT_COMPLETED" && (
          <DialogContentActionStyled>
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowConfirmDialog(false)}
            >
              取消
            </TextButton>
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                handleBillConfirm(selectedBill?.recordId, () =>
                  setShowConfirmDialog(false)
                )
              }
            >
              完成
            </TextButton>
          </DialogContentActionStyled>
        )}
      </ZoomiDialogStyled>
      <ZoomiDialogStyled minWidth={"25rem"} open={showBillDetailDialog}>
        <Typography color="primary" variant="h4">
          帳單明細({selectedBill?.yearMonth})
        </Typography>
        <DialogContent>
          {selectedBill?.billDetails.map((billDetail) => (
            <BillContentItemRowStyled>
              <Typography color="primary" variant="h5" marginBottom={"1rem"}>
                月租費({billDetail?.userContractRecordId})
              </Typography>
              <Typography color="primary" variant="h5" marginBottom={"1rem"}>
                $ {formatMoney(billDetail?.amount)}
              </Typography>
            </BillContentItemRowStyled>
          ))}
          <BillContentItemRowStyled>
            <Typography color="primary" variant="h5" marginBottom={"1rem"}>
              延遲繳款違約金
            </Typography>
            <Typography color="primary" variant="h5" marginBottom={"1rem"}>
              $ {formatMoney(selectedBill?.damagesAmount)}
            </Typography>
          </BillContentItemRowStyled>
        </DialogContent>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowBillDetailDialog(false)}
          >
            關閉
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default BillList;
