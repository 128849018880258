import { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { MemberApplicationStyled, MemberInputCard } from "./styled";
import {
  Typography,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TextButton } from "../../../components/Button";
import {
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock,
} from "../../../components/Card";
import useJobList from "../../../lib/api/useJobList";
import usePhoneTypeList from "../../../lib/api/usePhoneTypeList";
import useStorefrontList from "../../../lib/api/useStorefrontList";
import useContractCreate from "../../../lib/api/useContractCreate";
import { formatMoney } from "../../../lib/tools";
import { CONTRACT_DURING_MONTH } from "../../../config/setting";

const MemberApplication = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { member } = location.state;
  const [jobList, jobListLoading, fetchJobList] = useJobList();
  const [phoneTypeList, phoneTypeLoading, fetchPhoneTypeList] =
    usePhoneTypeList();
  const [storefronts, storefrontsLoading, fetchStorefront] =
    useStorefrontList();
  const [
    contractCreateLoading,
    contractParams,
    setContractParams,
    contractCreateResult,
    postContractCreate,
  ] = useContractCreate();

  const [selectedPhoneType, setSelectedPhoneType] = useState({
    name: null,
    capacity: null,
    color: null,
  });

  const [phoneNameList, setPhoneNameList] = useState(null);
  const [phoneCapacityList, setPhoneCapacityList] = useState(null);
  const [phoneColorList, setPhoneColorList] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!member) {
      handleGoBack();
    }

    fetchJobList();
    fetchPhoneTypeList();
    fetchStorefront();
    setContractParams({
      ...contractParams,
      userId: member.id,
      // durationMonth: CONTRACT_DURING_MONTH,
      discountAmount: 0,
    });
  }, []);

  useEffect(() => {}, [contractParams]);

  useEffect(() => {
    initPhoneSelectedOptions();
  }, [phoneTypeList]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({ ...error, [key]: null });
    }

    if (key === "contractName" && value === "") {
      setContractParams({ ...contractParams, [key]: 0 });
      return;
    }

    if (key === "detail" && value === "") {
      delete contractParams[key];
      setContractParams({ ...contractParams });
      return;
    }
    setContractParams({ ...contractParams, [key]: value });
  };

  const checkParams = (contractParams) => {
    let pass = true;
    let newError = { ...contractParams };

    for (let key in contractParams) {
      if (contractParams[key] === null || contractParams[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setError(newError);
    return pass;
  };

  const handleCreateMemberApplication = () => {
    // setContractParams(() => ({ ...contractParams, pickUpMillis: Date.now() }));
    if (checkParams(contractParams)) {
      postContractCreate(
        { ...contractParams, pickUpMillis: Date.now() },
        handleGoBack
      );
    }
  };

  //  取得手機選項
  const getPhoneOptionsList = (key, list) => {
    let options = {};
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!options[element[key]]) {
        options[element[key]] = true;
      }
    }

    return Object.keys(options);
  };

  const initPhoneSelectedOptions = () => {
    if (phoneTypeList) {
      setSelectedPhoneType({ ...selectedPhoneType });
      const nameList = getPhoneOptionsList("name", phoneTypeList);
      setPhoneNameList(nameList);
    }
  };

  const handleSelectPhoneTye = (type, value) => {
    if (error.phoneTypeId) {
      setError({ ...error, phoneTypeId: null });
    }

    if (type === "name") {
      const newList = phoneTypeList.filter((item) => item.name === value);
      const colorOptionList = getPhoneOptionsList("color", newList);
      setPhoneColorList(colorOptionList);
      setPhoneCapacityList(null);
      setSelectedPhoneType({
        ...selectedPhoneType,
        name: value,
        capacity: null,
        color: null,
      });
    } else if (type === "color") {
      const newList = phoneTypeList.filter(
        (item) => item.color === value && item.name === selectedPhoneType.name
      );
      const capacityOptionList = getPhoneOptionsList("capacity", newList);

      // 加上月租費的選項
      for (let i = 0; i < capacityOptionList.length; i++) {
        const element = capacityOptionList[i];
        const monthlyPrice = newList.find(
          (item) => item.capacity === element
        ).monthlyPrice;
        capacityOptionList[i] = {
          value: element,
          label: `${element}/ 月租 ${formatMoney(monthlyPrice)}`,
        };
      }

      setPhoneCapacityList(capacityOptionList);
      setSelectedPhoneType({
        ...selectedPhoneType,
        list: newList,
        color: value,
        capacity: null,
      });
    } else if (type === "capacity") {
      const newList = phoneTypeList.filter(
        (item) =>
          item.capacity === value &&
          item.name === selectedPhoneType.name &&
          item.color === selectedPhoneType.color
      );
      //  理論上只會有一筆
      setSelectedPhoneType({
        ...selectedPhoneType,
        capacity: value,
      });
      setContractParams({
        ...contractParams,
        phoneTypeId: newList[0].id,
        monthlyPrice: newList[0].monthlyPrice,
      });
    }
  };

  return (
    <MemberApplicationStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        門市申辦
      </Typography>
      <MemberInputCard color="primary" sx={{ textAlign: "left" }}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            姓名
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            disabled
            type="text"
            required
            defaultValue={member?.name || ""}
            size="small"
          />
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>身分字號</InputLabelStyled>
          <OutlinedInput
            fullWidth
            disabled
            type="text"
            required
            size="small"
            defaultValue={member?.idNumber}
          />
        </InputBlock>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            門市
          </InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={""}
            error={error.storefrontId}
            onChange={(e) => handleChangeParams("storefrontId", e.target.value)}
          >
            {storefronts ? (
              storefronts.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.storefrontId}>
            {error?.storefrontId}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>方案</InputLabelStyled>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => handleChangeParams("contractName", e.target.value)}
          >
            <FormControlLabel
              value="租購"
              control={<Radio size="small" />}
              label="租購"
            />
            <FormControlLabel
              value="舊手機換購"
              control={<Radio size="small" />}
              label="舊手機換購"
            />
          </RadioGroup>
          <FormHelperText error={error.contractName}>
            {error.contractName}
          </FormHelperText>
        </InputBlock>
        {contractParams.contractName === "舊手機換購" && (
          <InputBlock>
            <InputLabelStyled required>舊機折抵金額</InputLabelStyled>
            <OutlinedInput
              fullWidth
              type="number"
              required
              size="small"
              defaultValue={0}
              onChange={(e) =>
                handleChangeParams("discountAmount", e.target.value)
              }
              error={error.discountAmount}
            />
            <FormHelperText error={error.discountAmount}>
              {error.discountAmount}
            </FormHelperText>
          </InputBlock>
        )}

        {phoneNameList && (
          <InputBlock>
            <InputLabelStyled color="primary" required>
              手機型號
            </InputLabelStyled>
            <Select
              fullWidth
              displayEmpty
              required
              size="small"
              defaultValue={""}
              error={error.phoneTypeId}
              onChange={(e) => handleSelectPhoneTye("name", e.target.value)}
            >
              {phoneNameList ? (
                phoneNameList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
            <FormHelperText error={error.phoneTypeId}>
              {error.phoneTypeId}
            </FormHelperText>
          </InputBlock>
        )}
        {selectedPhoneType.name && phoneColorList && (
          <InputBlock>
            <InputLabelStyled color="primary" required>
              顏色
            </InputLabelStyled>
            <Select
              fullWidth
              required
              size="small"
              error={error.phoneTypeId}
              defaultValue={""}
              onChange={(e) => handleSelectPhoneTye("color", e.target.value)}
            >
              {phoneColorList ? (
                phoneColorList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
            <FormHelperText error={error.phoneTypeId}>
              {error.phoneTypeId}
            </FormHelperText>
          </InputBlock>
        )}
        {selectedPhoneType.color && phoneCapacityList && (
          <InputBlock>
            <InputLabelStyled color="primary" required>
              容量
            </InputLabelStyled>
            <Select
              fullWidth
              required
              size="small"
              defaultValue={""}
              error={error.phoneTypeId}
              onChange={(e) => handleSelectPhoneTye("capacity", e.target.value)}
            >
              {phoneCapacityList ? (
                phoneCapacityList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
            <FormHelperText error={error.phoneTypeId}>
              {error.phoneTypeId}
            </FormHelperText>
          </InputBlock>
        )}
        <InputBlock>
          <InputLabelStyled required>IMEI</InputLabelStyled>
          <OutlinedInput
            fullWidth
            size="small"
            type="text"
            required
            onChange={(e) => handleChangeParams("phoneImei", e.target.value)}
            error={error.phoneImei}
          />
          <FormHelperText error={error.phoneImei}>
            {error.phoneImei}
          </FormHelperText>
        </InputBlock>

        <InputBlock>
          <InputLabelStyled required>合約起始日期</InputLabelStyled>
          <OutlinedInput
            fullWidth
            size="small"
            type="date"
            required
            onChange={(e) =>
              handleChangeParams(
                "contractStartMillis",
                new Date(e.target.value).valueOf()
              )
            }
            error={error.contractStartMillis}
          />
          <FormHelperText error={error.contractStartMillis}>
            {error.contractStartMillis}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled>保固</InputLabelStyled>
          <OutlinedInput
            fullWidth
            size="small"
            type="text"
            multiline
            rows={5}
            onChange={(e) => handleChangeParams("detail", e.target.value)}
          />
        </InputBlock>
        <MemberApplicationControlBlock>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleGoBack}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleCreateMemberApplication}
          >
            送出
          </TextButton>
        </MemberApplicationControlBlock>
      </MemberInputCard>
    </MemberApplicationStyled>
  );
};

export default MemberApplication;
