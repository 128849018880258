import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useFaqList from "../../../lib/api/useFaqList";
import {
  Typography,
  IconButton,
  OutlinedInput,
  Card,
  Button,
  FormHelperText,
  Box,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InputBlock, InputLabelStyled } from "../../../components/Card";
import { PAGE_SIZE, PAGE_START } from "../../../config/setting";
import useFaqCreate from "../../../lib/api/useFaqCreate";
import useFaqModify from "../../../lib/api/useFaqModify";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkRequireParams } from "../../../lib/tools";

const FaqEdit = () => {
  const { status, id } = useParams();
  const navigate = useNavigate();
  const [faqCreate, faqCreateLoading, requestFaqCreate] = useFaqCreate();
  const [faqModify, faqModifyLoading, requestFaqModify] = useFaqModify();
  const [faqList, faqListLoading, fetchFaqList] = useFaqList();
  const [newFaqParams, setNewFaqParams] = useState({
    title: null,
    content: null,
    status: "ENABLE",
  });
  const [faqError, setFaqError] = useState({});

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      fetchFaqList({
        page: PAGE_START,
        size: PAGE_SIZE,
        id: id,
      });
    }
  }, []);

  useEffect(() => {
    if (faqList) {
      setNewFaqParams({
        id: id,
        title: faqList?.faqDtos[0].title,
        content: faqList?.faqDtos[0].content,
        status: faqList?.faqDtos[0].status,
      });
    }
  }, [faqList]);

  useEffect(() => {
    if (faqCreate || faqModify) {
      handleGoBack();
    }
  }, [faqCreate, faqModify]);

  const handleChangeParams = (key, value) => {
    if (faqError[key]) {
      setFaqError({ ...faqError, [key]: null });
    }

    setNewFaqParams({ ...newFaqParams, [key]: value });
  };

  const handleSaveFaq = (status) => {
    if (checkRequireParams(newFaqParams, setFaqError)) {
      if (status === "create") {
        requestFaqCreate(newFaqParams);
      } else {
        requestFaqModify(newFaqParams);
      }
    }
  };

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        {status === "create" ? "新增" : "編輯"}常見問題
      </Typography>
      <Card color="primary" sx={{ textAlign: "left", padding: "2rem" }}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            問題
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            value={newFaqParams?.title || ""}
            required
            size="small"
            onChange={(e) => handleChangeParams("title", e.target.value)}
            error={!!faqError.title}
          />
          <FormHelperText error={!!faqError.title}>
            {faqError.title}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            內容
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            defaultValue={newFaqParams?.content || ""}
            size="small"
            onChange={(e) => handleChangeParams("content", e.target.value)}
            error={!!faqError.content}
            multiline
            rows={10}
          />
          <FormHelperText error={!!faqError.content}>
            {faqError.content}
          </FormHelperText>
        </InputBlock>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            loading={faqCreateLoading || faqModifyLoading}
            onClick={handleGoBack}
          >
            取消
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            loading={faqCreateLoading || faqModifyLoading}
            onClick={() => handleSaveFaq(status)}
          >
            儲存
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};

export default FaqEdit;
