import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { ContractDetailStyled, InformationCard } from "./styled";
import useContractList from "../../../lib/api/useContractList";
import useBillList from "../../../lib/api/useBillList";
import useContractModify from "../../../lib/api/useContractModify";
import useContractTerminate from "./../../../lib/api/useContractTerminate";
import {
  Typography,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormHelperText,
  DialogContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TextButton } from "../../../components/Button";
import {
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock,
} from "../../../components/Card";

import { formatTimestamp, formatMoney } from "../../../lib/tools";
import {
  CONTRACT_TERMINATE_REASON_OPTIONS,
  TERMINATE_PHONE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "../../../config/enum";
import useMemberList from "../../../lib/api/useMemberList";
import { MEMBER_KYC_STATUS } from "../../../config/enum";
import { PAGE_SIZE, PAGE_START } from "../../../config/setting";
import { DataGrid } from "@mui/x-data-grid";
import {
  InformationBlockStyled,
  InformationCardStyled,
  InformationBlockTitleStyled,
  InformationBlockContentStyled,
} from "../../../components/Card";
import { ContractStatus } from "../../../config/setting";
import { ZoomiDataGrid, ZoomiPagination } from "../../../components/DataGrid";
import {
  ZoomiDialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentActionStyled,
} from "../../../components/Dialog";
import { getBillPaymentStatus } from "../../../config/setting";

const userInformationConfig = [
  {
    headerName: "ID",
    key: "id",
  },
  {
    headerName: "帳號",
    key: "email",
  },
  {
    headerName: "姓名",
    key: "name",
  },
  {
    headerName: "註冊時間",
    key: "createMillis",
    renderCell: (item) => {
      return <>{item ? new Date(item).toLocaleString() : ""}</>;
    },
  },
  {
    headerName: "身分證字號",
    key: "idNumber",
  },
  {
    headerName: "手機號碼",
    key: "phoneNumber",
  },
];

const dataGridColumns = [
  {
    field: "recordId",
    headerName: "繳費單號",
    minWidth: 180,
    flex: 1.5,
  },
  { field: "yearMonth", headerName: "月份", minWidth: 50, flex: 1 },
  {
    field: "totalAmount",
    headerName: "費用",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => <>{formatMoney(params.row.totalAmount, 2)}</>,
  },
  {
    field: "status",
    headerName: "狀態",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <>{getBillPaymentStatus(params.row.status)?.label}</>
    ),
  },
  {
    field: "paymentDeadlineMillis",
    headerName: "繳費日期",
    flex: 1.5,
    minWidth: 150,
    renderCell: (params) => (
      <div>{new Date(params.row.paymentDeadlineMillis).toLocaleString()}</div>
    ),
  },
  {
    field: "payType",
    headerName: "繳費方式",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <>{PAYMENT_TYPE_OPTIONS.get(params.row.payType)?.label}</>
    ),
  },
  {
    field: "remark",
    headerName: "備註",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => <>{params.row.isOverdue && "逾期"}</>,
  },
];

const ContractDetail = () => {
  const { recordId } = useParams();
  // const location = useLocation();
  // let { contract } = location.state;
  const navigate = useNavigate();
  const [contractList, contractLoading, fetchContractList] = useContractList();
  const [memberList, memberlistLoading, fetchMemberList] = useMemberList();
  const [billList, billListLoading, fetchBillList] = useBillList();
  const [contractModify, contractModifyLoading, requestContractModify] =
    useContractModify();
  const [
    contractTerminate,
    contractTerminateLoading,
    requestContractTerminate,
  ] = useContractTerminate();

  const [billParams, setBillParams] = useState({
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const [openImeiDialog, setOpenImeiDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openTerminateDialog, setOpenTerminateDialog] = useState(false);

  const [modifyParams, setModifyParams] = useState({
    recordId: recordId,
    phoneImei: null,
  });

  const [terminationParams, setTerminationParams] = useState({
    recordId: recordId,
    endHandlingType: null,
    early: null,
  });

  const [error, setError] = useState({});
  const [terminateError, setTerminateError] = useState({});

  const handleChangeTerminateParams = (key, value) => {
    if (terminateError[key]) {
      setTerminateError({ ...error, [key]: null });
    }

    if (key === "early") {
      if (value === true) {
        setTerminationParams({
          ...terminationParams,
          [key]: true,
          terminateReason: null,
        });
        return;
      } else {
        delete terminationParams.terminateReason;
        setTerminationParams({
          ...terminationParams,
          [key]: false,
        });
        return;
      }
    }

    if (key === "endHandlingType") {
      if (value === "BUYOUT") {
        setTerminationParams({
          ...terminationParams,
          [key]: value,
          buyoutPrice: null,
        });
        return;
      } else {
        delete terminationParams.buyoutPrice;
        setTerminationParams({
          ...terminationParams,
          [key]: value,
        });
        return;
      }
    }
    setTerminationParams({ ...terminationParams, [key]: value });
  };

  const checkTerminateParams = (contractParams) => {
    let pass = true;
    let newError = { ...contractParams };

    for (let key in contractParams) {
      if (key === "detail") continue;

      if (contractParams[key] === null || contractParams[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setTerminateError(newError);
    return pass;
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({ ...error, [key]: null });
    }
    setModifyParams({ ...modifyParams, [key]: value });
  };

  const checkParams = (contractParams) => {
    let pass = true;
    let newError = { ...contractParams };

    for (let key in contractParams) {
      if (key === "detail") continue;

      if (contractParams[key] === null || contractParams[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setError(newError);
    return pass;
  };

  const contractInformationConfig = [
    {
      headerName: "合約期間",
      key: "contractStartMillis",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <p>
            {params.contractStartMillis
              ? formatTimestamp(params.contractStartMillis)
              : ""}
          </p>

          <p>
            {params.contractEndMillis
              ? "~" + formatTimestamp(params.contractEndMillis)
              : ""}
          </p>
        </div>
      ),
    },
    {
      headerName: "方案",
      key: "contractName",
    },
    {
      headerName: "手機型號",
      key: "phoneType",
      renderCell: (params) => (
        <>
          {`${params.phoneType.phoneName} (${params.phoneType.phoneCapacity} ${params.phoneType.phoneColor})`}
        </>
      ),
    },
    {
      headerName: "建立時間",
      key: "createMillis",
      renderCell: (params) => (
        <div>
          {params.createMillis
            ? new Date(params.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      headerName: "IMEI",
      key: "phoneImei",
      renderHeader: (text) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {text}
            <TextButton
              sx={{ minWidth: "unset", padding: "0 0.5rem" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenImeiDialog(true)}
            >
              修改
            </TextButton>
          </Box>
        );
      },
    },
    {
      headerName: "舊機折抵",
      key: "discountAmount",
      renderCell: (params) => (
        <div>
          <div>{formatMoney(params.discountAmount)}</div>
        </div>
      ),
    },
    {
      headerName: "狀態",
      key: "status",
      renderCell: (params) => <div>{ContractStatus(params)?.label}</div>,
    },
    {
      headerName: "保固",
      key: "detail",
      renderCell: (params) => <div>點擊按鈕查看內容 </div>,
      renderHeader: (text) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {text}
            <TextButton
              sx={{ minWidth: "unset", padding: "0 0.5rem" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenDetailDialog(true)}
            >
              查看
            </TextButton>
          </Box>
        );
      },
    },
    {
      headerName: "結束原因",
      key: "endHandlingType",
      disabled: (params) => {
        return params.status === "EARLY_TERMINATED";
      },
      renderCell: (params) => (
        <div>{CONTRACT_TERMINATE_REASON_OPTIONS.get(params.status)?.label}</div>
      ),
    },
    {
      headerName: "其他原因",
      key: "terminateReason",
      disabled: (params) => {
        return !!params.terminateReason;
      },
    },
    {
      headerName: "手機處理方式",
      key: "endHandlingType",
      disabled: (params) => {
        return !!params.endHandlingType;
      },
      renderCell: (params) => (
        <div>{TERMINATE_PHONE_OPTIONS.get(params.endHandlingType)?.label}</div>
      ),
    },
    {
      headerName: "原租賃手機價格",
      key: "buyoutPrice",
      disabled: (params) => {
        return !!params.buyoutPrice;
      },
      renderCell: (params) => <div>{formatMoney(params.buyoutPrice)}</div>,
    },
  ];

  useEffect(() => {
    // if (!recordId || !contract) {
    //   handleGoBack();
    // }

    if (!recordId) {
      handleGoBack();
    }

    fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
  }, []);

  useEffect(() => {
    if (contractList) {
      const contract = contractList.userContracts[0];
      fetchMemberList({
        id: contract.userId,
        page: PAGE_START,
        size: PAGE_SIZE,
      });
      setModifyParams({
        ...modifyParams,
        phoneImei: contract?.phoneImei,
        detail: contract?.detail,
      });
    }
  }, [contractList]);

  useEffect(() => {
    fetchBillList(billParams);
  }, [billParams.page, billParams.pageSize]);

  useEffect(() => {
    if (contractModify) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
    }
  }, [contractModify]);

  useEffect(() => {
    if (contractTerminate) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
    }
  }, [contractTerminate]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSelectedPage = (event, value) => {
    setBillParams({ ...billParams, page: value - 1 });
  };

  const handleConfirmModify = (close) => {
    if (checkParams(modifyParams)) {
      requestContractModify(modifyParams);
      close();
    }
  };

  const handleCancelModify = (key, close) => {
    const originValue = contractList.userContracts[0][key];
    setModifyParams({ ...modifyParams, [key]: originValue });
    close();
  };

  const handleConfirmTerminate = (close) => {
    if (checkTerminateParams(terminationParams)) {
      requestContractTerminate(terminationParams);
      close();
    }
  };

  return (
    <ContractDetailStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        合約詳情
      </Typography>

      <InformationCardStyled variant="outlined" color="primary">
        <Typography
          color="primary"
          variant="h4"
          align="left"
          sx={{ width: "100%", marginBottom: "1rem" }}
        >
          會員資料
        </Typography>
        {memberList &&
          userInformationConfig.map((item, index) => {
            return (
              <div key={index}>
                <InformationBlockStyled>
                  <InformationBlockTitleStyled>
                    {item.renderHeader && item.key
                      ? item.renderHeader(memberList?.userDtos[0][item.key])
                      : item.headerName}
                  </InformationBlockTitleStyled>
                  <InformationBlockContentStyled>
                    {item.renderCell && item.key
                      ? item.renderCell(memberList?.userDtos[0][item.key])
                      : memberList?.userDtos[0][item.key]}
                  </InformationBlockContentStyled>
                </InformationBlockStyled>
              </div>
            );
          })}
      </InformationCardStyled>
      <InformationCardStyled variant="outlined" color="primary">
        <Box sx={{ display: "flex", width: "100%" }}>
          <Typography
            color="primary"
            variant="h4"
            align="left"
            sx={{ width: "100%", marginBottom: "1rem" }}
          >
            合約資料
          </Typography>
          {contractList &&
            ContractStatus(contractList.userContracts[0]).label !==
              "已結束" && (
              <TextButton
                sx={{ minWidth: "5rem" }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setOpenTerminateDialog(true)}
              >
                結束合約
              </TextButton>
            )}
        </Box>
        {contractList &&
          contractInformationConfig.map((item, index) => {
            if (
              typeof item.disabled === "function" &&
              !item.disabled(contractList.userContracts[0])
            ) {
              return null;
            }
            return (
              <div key={index}>
                <InformationBlockStyled>
                  <InformationBlockTitleStyled>
                    {item.renderHeader && item.key
                      ? item.renderHeader(item.headerName)
                      : item.headerName}
                  </InformationBlockTitleStyled>
                  <InformationBlockContentStyled>
                    {item.renderCell && item.key
                      ? item.renderCell(contractList.userContracts[0])
                      : contractList.userContracts[0][item.key]}
                  </InformationBlockContentStyled>
                </InformationBlockStyled>
              </div>
            );
          })}
      </InformationCardStyled>
      {/* {billList ? (
        <>
          <Typography color="primary" variant="h4" align="left">
            帳單
          </Typography>
          <ZoomiDataGrid
            getRowId={(row) => row.recordId}
            columns={dataGridColumns}
            loading={billListLoading}
            rows={billList?.bills}
            autoHeight
            sx={{ "--DataGrid-overlayHeight": "300px" }}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={billList?.totalPage}
      /> */}

      <ZoomiDialogStyled
        open={openImeiDialog}
        onClose={() =>
          handleCancelModify("phoneImei", () => setOpenImeiDialog(false))
        }
      >
        <DialogTitleStyled color="primary" variant="h4">
          修改 IMEI
        </DialogTitleStyled>
        <DialogContentStyled>
          <InputBlock>
            <InputLabelStyled color="primary" required>
              IMEI
            </InputLabelStyled>
            <OutlinedInput
              fullWidth
              type="text"
              required
              defaultValue={modifyParams?.phoneImei || ""}
              size="small"
              error={!!error.phoneImei}
              onChange={(e) => handleChangeParams("phoneImei", e.target.value)}
            />
          </InputBlock>
          <FormHelperText error={error.phoneImei}>
            {error?.phoneImei}
          </FormHelperText>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleCancelModify("phoneImei", () =>
                setOpenImeiDialog(!openImeiDialog)
              )
            }
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleConfirmModify(() => setOpenImeiDialog(false))}
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>

      <ZoomiDialogStyled
        open={openDetailDialog}
        onClose={() =>
          handleCancelModify("detail", () => setOpenDetailDialog(false))
        }
      >
        <DialogTitleStyled color="primary" variant="h4">
          修改保固內容
        </DialogTitleStyled>
        <DialogContentStyled>
          <InputBlock>
            <InputLabelStyled color="primary" required>
              保固內容
            </InputLabelStyled>
            <OutlinedInput
              fullWidth
              type="text"
              defaultValue={modifyParams?.detail || ""}
              size="small"
              multiline
              rows={5}
              onChange={(e) => handleChangeParams("detail", e.target.value)}
            />
          </InputBlock>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleCancelModify("detail", () => setOpenDetailDialog(false))
            }
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleConfirmModify(() => setOpenDetailDialog(false))
            }
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
      <ZoomiDialogStyled
        open={openTerminateDialog}
        onClose={() => setOpenTerminateDialog(false)}
      >
        <DialogTitleStyled color="primary" variant="h4">
          結束合約
        </DialogTitleStyled>
        <DialogContentStyled>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            帳號：{memberList?.userDtos[0].email}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            姓名：{memberList?.userDtos[0].name}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            合約期間：
            {formatTimestamp(
              contractList?.userContracts[0].contractStartMillis
            )}
            ~{formatTimestamp(contractList?.userContracts[0].contractEndMillis)}
          </Typography>
          <InputBlock>
            <InputLabelStyled color="primary" required>
              結束原因
            </InputLabelStyled>
            <Select
              fullWidth
              displayEmpty
              required
              size="small"
              defaultValue={
                terminationParams?.early === null
                  ? ""
                  : terminationParams?.early
              }
              error={!!terminateError.early}
              onChange={(e) =>
                handleChangeTerminateParams("early", e.target.value)
              }
            >
              {Array.from(CONTRACT_TERMINATE_REASON_OPTIONS.keys()).map(
                (key, index) => (
                  <MenuItem
                    key={index}
                    value={CONTRACT_TERMINATE_REASON_OPTIONS.get(key).value}
                  >
                    {CONTRACT_TERMINATE_REASON_OPTIONS.get(key).label}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText error={!!terminateError.early}>
              {terminateError?.early}
            </FormHelperText>
          </InputBlock>
          {terminationParams?.early && (
            <InputBlock>
              <InputLabelStyled color="primary" required>
                其他原因
              </InputLabelStyled>
              <OutlinedInput
                defaultValue={terminationParams?.terminateReason || ""}
                fullWidth
                type="text"
                size="small"
                onChange={(e) =>
                  handleChangeTerminateParams("terminateReason", e.target.value)
                }
              />
            </InputBlock>
          )}
          <InputBlock>
            <InputLabelStyled color="primary" required>
              手機處理方式
            </InputLabelStyled>
            <Select
              fullWidth
              displayEmpty
              required
              size="small"
              defaultValue={terminationParams?.endHandlingType || ""}
              error={terminateError.endHandlingType}
              onChange={(e) =>
                handleChangeTerminateParams("endHandlingType", e.target.value)
              }
            >
              {Array.from(TERMINATE_PHONE_OPTIONS.keys()).map((key, index) => (
                <MenuItem
                  key={index}
                  value={TERMINATE_PHONE_OPTIONS.get(key).value}
                >
                  {TERMINATE_PHONE_OPTIONS.get(key).label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={terminateError.endHandlingType}>
              {terminateError?.endHandlingType}
            </FormHelperText>
          </InputBlock>
          {terminationParams?.endHandlingType === "BUYOUT" && (
            <InputBlock>
              <InputLabelStyled color="primary" required>
                原租賃手機價格
              </InputLabelStyled>
              <OutlinedInput
                fullWidth
                defaultValue={terminationParams?.buyoutPrice || ""}
                type="number"
                size="small"
                error={!!terminateError.buyoutPrice}
                onChange={(e) =>
                  handleChangeTerminateParams("buyoutPrice", e.target.value)
                }
              />
              <FormHelperText error={terminateError.buyoutPrice}>
                {terminateError?.buyoutPrice}
              </FormHelperText>
            </InputBlock>
          )}
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenTerminateDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleConfirmTerminate(() => setOpenTerminateDialog(false))
            }
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </ContractDetailStyled>
  );
};

export default ContractDetail;
