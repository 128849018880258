import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { MESSAGE_TYPE } from "../../config/enum";
import { useSnackbar } from "notistack";

const useMemberKYCApproval = () => {
  const [handleError] = useErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [memberKYCApproval, setMemberKYCApproval] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchMemberKYCApproval = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        API_URL.USER_KYC_APPROVAL,
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setMemberKYCApproval(res.content);
        enqueueSnackbar("審核成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      enqueueSnackbar("失敗：" + error, { variant: MESSAGE_TYPE.ERROR });
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [memberKYCApproval, isLoading, fetchMemberKYCApproval],
    [memberKYCApproval, isLoading, fetchMemberKYCApproval]
  );
};

export default useMemberKYCApproval;
