import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PAGE_START, PAGE_SIZE } from "../../../config/setting";
import useContractList from "../../../lib/api/useContractList";
import useMemberList from "../../../lib/api/useMemberList";
import {
  InformationCardStyled,
  InformationBlockStyled,
  InformationBlockTitleStyled,
  InformationBlockContentStyled,
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock,
} from "../../../components/Card";
import {
  Typography,
  IconButton,
  Card,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { MEMBER_KYC_STATUS } from "../../../config/enum";
import {
  formatMoney,
  checkRequireParams,
  formatTimestamp,
} from "../../../lib/tools";
import {
  getContractProgressText,
  getContractStatusText,
} from "./../../../config/setting";
import {
  CONTRACT_SOURCE_OPTIONS,
  KYC_RESULT_OPTIONS,
} from "../../../config/enum";
import useStorefrontList from "../../../lib/api/useStorefrontList";
import useContractAppointment from "../../../lib/api/useContractAppointment";
import useContractApproval from "../../../lib/api/useContractApproval";
import LoadingButton from "@mui/lab/LoadingButton";
import useContractCancel from "../../../lib/api/useContractCancel";
import {
  ZoomiDialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentActionStyled,
} from "../../../components/Dialog";
import { TextButton } from "../../../components/Button";
import useContractFormation from "../../../lib/api/useContractFormation";
import usePhoneTypeList from "../../../lib/api/usePhoneTypeList";
import useAdminStorefront from "../../../lib/api/useAdminStorefrontList";

const BidVerification = () => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const [contractList, contractLoading, fetchContractList] = useContractList();
  const [memberList, memberlistLoading, fetchMemberList] = useMemberList();
  const [storefronts, storefrontsLoading, fetchStorefront] =
    useStorefrontList();
  const [adminStorefronts, adminStoreLoading, fetchAdminStorefront] =
    useAdminStorefront();

  //  用來存頁面會用到的門市資訊
  const [storefrontMap, setStorefrontMap] = useState({});

  const [
    contractAppointment,
    contractAppointmentLoading,
    requestContractAppointment,
  ] = useContractAppointment();
  const [contractApproval, contractApprovalLoading, requestContractApproval] =
    useContractApproval();

  const [contractCancel, contractCancelLoading, requestContractCancel] =
    useContractCancel();

  const [
    contractFormation,
    contractFormationLoading,
    requestContractFormation,
  ] = useContractFormation();
  const [phoneTypeList, phoneTypeLoading, fetchPhoneTypeList] =
    usePhoneTypeList();
  const [showContractCancelDialog, setShowContractCancelDialog] =
    useState(false);

  const [approvalParams, setApprovalParams] = useState({
    recordId,
    pass: null,
  });

  const [approvalError, setApprovalError] = useState({});

  const [appointmentParams, setAppointmentParams] = useState({
    recordId,
    storefrontId: null,
    pickUpMillis: null,
  });

  const [formationParams, setFormationParams] = useState({
    recordId,
    contractName: null,
    phoneTypeId: null,
    phoneImei: null,
    monthlyPrice: null,
    discountAmount: 0,
    contractStartMillis: null,
  });
  const [formationError, setFormationError] = useState({});
  const [selectedPhoneType, setSelectedPhoneType] = useState({
    name: null,
    capacity: null,
    color: null,
  });
  const [phoneNameList, setPhoneNameList] = useState(null);
  const [phoneCapacityList, setPhoneCapacityList] = useState(null);
  const [phoneColorList, setPhoneColorList] = useState(null);
  const [showContractFormationDialog, setShowContractFormationDialog] =
    useState(false);
  const [appointmentError, setAppointmentError] = useState({});

  const [userProgressStatus, setUserProgressStatus] = useState({});
  const [showEditAppointmentDialog, setShowEditAppointmentDialog] =
    useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const getStorefrontName = (id) => {
    if (adminStorefronts) {
      const storefront = adminStorefronts?.storefronts?.find(
        (item) => item.id === id
      );
      return storefront?.name;
    }

    return "";
  };

  useEffect(() => {
    if (!recordId) {
      handleGoBack();
    }

    fetchStorefront();
    fetchPhoneTypeList();
    fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
  }, []);

  useEffect(() => {
    if (contractList) {
      const contract = contractList.userContracts[0];
      fetchMemberList({
        id: contract.userId,
        page: PAGE_START,
        size: PAGE_SIZE,
      });
      setUserProgressStatus(getContractProgressText(contract.status));
      fetchAdminStorefront({
        id: contract.storefrontId,
        page: PAGE_START,
        size: PAGE_SIZE,
      });
      // setup formation params
      // get phone Id first

      // const colorList = phoneTypeList.filter(
      //   (item) => item.name === contract?.phoneType?.phoneName
      // );
      // const colorOptionList = getPhoneOptionsList("color", colorList);
      // setPhoneColorList(colorOptionList);

      // const capacityList = phoneTypeList.filter(
      //   (item) =>
      //     item.color === contract?.phoneType?.phoneColor &&
      //     item.name === selectedPhoneType.name
      // );

      // const capacityOptionList = getPhoneOptionsList("capacity", capacityList);

      // // 加上月租費的選項
      // for (let i = 0; i < capacityOptionList.length; i++) {
      //   const element = capacityOptionList[i];
      //   const monthlyPrice = capacityList.find(
      //     (item) => item.capacity === element
      //   ).monthlyPrice;
      //   capacityOptionList[i] = {
      //     value: element,
      //     label: `${element}/ 月租 ${formatMoney(monthlyPrice)}`,
      //   };
      // }

      // setPhoneCapacityList(capacityOptionList);

      // const finalPhoneList = phoneTypeList.filter(
      //   (item) =>
      //     item.capacity === contract?.phoneType?.phoneCapacity &&
      //     item.name === contract?.phoneType?.phoneName &&
      //     item.color === contract?.phoneType?.phoneColor
      // );

      // setSelectedPhoneType({
      //   ...selectedPhoneType,
      //   name: contract?.phoneType?.phoneName,
      //   capacity: contract?.phoneType?.phoneCapacity,
      //   color: contract?.phoneType?.phoneColor,
      // });

      let newParams = {
        ...formationParams,
        contractName: contract?.contractName,
        phoneImei: contract?.phoneImei,
        discountAmount: contract?.discountAmount || 0,
        contractStartMillis: contract?.contractStartMillis,
        // phoneTypeId: finalPhoneList[0].id,
        // monthlyPrice: finalPhoneList[0].monthlyPrice,
      };

      if (contract?.detail) {
        newParams.detail = contract?.detail;
      }

      setFormationParams(newParams);
    }
  }, [contractList, phoneTypeList]);

  useEffect(() => {
    if (contractCancel) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
    }
  }, [contractCancel]);

  useEffect(() => {
    if (contractFormation) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
    }
  }, [contractFormation]);

  useEffect(() => {
    if (contractApproval) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
      setShowContractFormationDialog(false);
    }
  }, [contractApproval]);

  useEffect(() => {
    if (memberList) {
      fetchAdminStorefront({
        id: memberList?.userDtos[0].storefrontId,
        page: PAGE_START,
        size: PAGE_SIZE,
      });
    }
  }, [memberList]);

  useEffect(() => {
    initPhoneSelectedOptions();
  }, [phoneTypeList]);

  useEffect(() => {
    if (contractAppointment) {
      fetchContractList({ recordId, page: PAGE_START, size: PAGE_SIZE });
    }
  }, [contractAppointment]);

  useEffect(() => {
    if (adminStorefronts) {
      let newStorefrontMap = {};
      for (let i = 0; i < adminStorefronts.length; i++) {
        const element = adminStorefronts[i];
        newStorefrontMap[element.id] = element.name;
      }
      setStorefrontMap(newStorefrontMap);
    }
  }, [adminStorefronts]);

  const handleChangeParams = (
    key,
    value,
    params,
    setParams,
    error,
    setError
  ) => {
    if (error[key]) {
      setError({ ...error, [key]: null });
    }

    if (key === "contractName" && value === "") {
      setParams({ ...params, [key]: 0 });
      return;
    }

    if (key === "pass") {
      if (value === false) {
        setParams({ ...params, [key]: false, rejectReason: null });
        return;
      } else {
        delete params.rejectReason;
        setParams({ ...params, [key]: true });

        return;
      }
    } else {
      setParams({ ...params, [key]: value });
    }
  };

  const handleConfirmApproval = () => {
    if (checkRequireParams(approvalParams, setApprovalError)) {
      requestContractApproval(approvalParams);
    }
  };

  const handleConfirmAppointment = (callBack) => {
    if (checkRequireParams(appointmentParams, setAppointmentError)) {
      requestContractAppointment(appointmentParams);
      callBack?.();
    }
  };

  const handleConfirmFormation = (onClose) => {
    if (checkRequireParams(formationParams, setFormationError)) {
      requestContractFormation(formationParams);
      onClose();
    }
  };

  const handleConfirmContractCancel = (onClose) => {
    requestContractCancel({ recordId });
    onClose();
  };

  //  取得手機選項
  const getPhoneOptionsList = (key, list) => {
    let options = {};
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!options[element[key]]) {
        options[element[key]] = true;
      }
    }

    return Object.keys(options);
  };

  const initPhoneSelectedOptions = () => {
    if (phoneTypeList) {
      setSelectedPhoneType({ ...selectedPhoneType });
      const nameList = getPhoneOptionsList("name", phoneTypeList);
      setPhoneNameList(nameList);
    }
  };

  const handleSelectPhoneTye = (type, value) => {
    if (type === "name") {
      const newList = phoneTypeList.filter((item) => item.name === value);
      const colorOptionList = getPhoneOptionsList("color", newList);
      setPhoneColorList(colorOptionList);
      setPhoneCapacityList(null);
      setSelectedPhoneType({
        ...selectedPhoneType,
        name: value,
        capacity: null,
        color: null,
      });
    } else if (type === "color") {
      const newList = phoneTypeList.filter(
        (item) => item.color === value && item.name === selectedPhoneType.name
      );
      const capacityOptionList = getPhoneOptionsList("capacity", newList);

      // 加上月租費的選項
      for (let i = 0; i < capacityOptionList.length; i++) {
        const element = capacityOptionList[i];
        const monthlyPrice = newList.find(
          (item) => item.capacity === element
        ).monthlyPrice;
        capacityOptionList[i] = {
          value: element,
          label: `${element}/ 月租 ${formatMoney(monthlyPrice)}`,
        };
      }

      setPhoneCapacityList(capacityOptionList);
      setSelectedPhoneType({
        ...selectedPhoneType,
        list: newList,
        color: value,
        capacity: null,
      });
    } else if (type === "capacity") {
      const newList = phoneTypeList.filter(
        (item) =>
          item.capacity === value &&
          item.name === selectedPhoneType.name &&
          item.color === selectedPhoneType.color
      );
      //  理論上只會有一筆
      setSelectedPhoneType({
        ...selectedPhoneType,
        capacity: value,
      });
      setFormationParams({
        ...formationParams,
        phoneTypeId: newList[0].id,
        monthlyPrice: newList[0].monthlyPrice,
      });
    }
  };

  const userInformationConfig = [
    {
      headerName: "ID",
      key: "id",
    },
    {
      headerName: "帳號",
      key: "email",
    },
    {
      headerName: "姓名",
      key: "name",
    },
    {
      headerName: "出生日期",
      key: "birthdayStr",
    },
    {
      headerName: "身分證字號",
      key: "idNumber",
    },
    {
      headerName: "手機號碼",
      key: "phoneNumber",
    },
    {
      headerName: "實名認證",
      key: "kycStatus",
      renderCell: (item) => {
        return <>{MEMBER_KYC_STATUS.get(item)?.label}</>;
      },
    },
  ];

  const userContractInformationConfig = [
    {
      headerName: "方案",
      key: "originalContractName",
    },
    {
      headerName: "手機型號",
      key: "originalPhoneType",
      renderCell: (item) => (
        <div>{`${item.originalPhoneType.phoneName}(${item.originalPhoneType.phoneColor}/${item.originalPhoneType.phoneCapacity})`}</div>
      ),
    },
    {
      headerName: "月租費",
      key: "originalMonthlyPrice",
      renderCell: (item) => <div>{formatMoney(item.originalMonthlyPrice)}</div>,
    },
    {
      headerName: "建立時間",
      key: "createMillis",
      renderCell: (item) => (
        <div>
          {item.createMillis
            ? new Date(item.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      headerName: "來源",
      key: "createSource",
      renderCell: (item) => (
        <div>
          {CONTRACT_SOURCE_OPTIONS.get(item.createSource).label +
            (item.storefrontId && item.createSource === "STOREFRONT"
              ? `(${getStorefrontName(item.storefrontId)})`
              : "")}
        </div>
      ),
    },
    {
      headerName: "審核狀態",
      key: "status",
      renderCell: (item) => {
        return <div>{getContractStatusText(item.status)}</div>;
      },
    },
    {
      //  只有通過的才有進度
      headerName: "進度",
      key: "status",
      renderCell: (item) => (
        <div>{getContractProgressText(item.status) || "--"}</div>
      ),
    },
  ];

  return (
    <div
      style={{
        paddingBottom: "2rem",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        申辦審核
      </Typography>
      <InformationCardStyled
        sx={{ margin: "0.4rem 0" }}
        variant="outlined"
        color="primary"
      >
        <Typography
          color="primary"
          variant="h4"
          align="left"
          sx={{ width: "100%", marginBottom: "1rem" }}
        >
          申辦人資料
        </Typography>
        {memberList &&
          userInformationConfig.map((item, index) => {
            return (
              <div key={index}>
                <InformationBlockStyled>
                  <InformationBlockTitleStyled>
                    {item.renderHeader && item.key
                      ? item.renderHeader(memberList?.userDtos[0]?.[item.key])
                      : item.headerName}
                  </InformationBlockTitleStyled>
                  <InformationBlockContentStyled>
                    {item.renderCell && item.key
                      ? item.renderCell(memberList?.userDtos[0][item.key])
                      : memberList?.userDtos[0][item.key]}
                  </InformationBlockContentStyled>
                </InformationBlockStyled>
              </div>
            );
          })}
      </InformationCardStyled>
      <InformationCardStyled
        sx={{ margin: "0.4rem 0" }}
        variant="outlined"
        color="primary"
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Typography
            color="primary"
            variant="h4"
            align="left"
            sx={{ width: "100%", marginBottom: "1rem" }}
          >
            申辦內容
          </Typography>
          {(userProgressStatus === "待預約" ||
            userProgressStatus === "待取貨") && (
            <LoadingButton
              sx={{ minWidth: "5rem" }}
              variant="contained"
              size="small"
              color="primary"
              loading={contractCancelLoading}
              onClick={() => setShowContractCancelDialog(true)}
            >
              取消申辦
            </LoadingButton>
          )}
        </Box>
        {contractList &&
          userContractInformationConfig.map((item, index) => {
            return (
              <div key={index}>
                <InformationBlockStyled>
                  <InformationBlockTitleStyled>
                    {item.renderHeader && item.key
                      ? item.renderHeader(contractList?.userContracts[0])
                      : item.headerName}
                  </InformationBlockTitleStyled>
                  <InformationBlockContentStyled>
                    {item.renderCell && item.key
                      ? item.renderCell(contractList?.userContracts[0])
                      : contractList?.userContracts[0]?.[item.key]}
                  </InformationBlockContentStyled>
                </InformationBlockStyled>
              </div>
            );
          })}
      </InformationCardStyled>
      {(contractList?.userContracts[0].status === "IN_REVIEW" ||
        contractList?.userContracts[0].status === "REJECTED") && (
        <Card
          variant="outlined"
          color="primary"
          sx={{
            padding: "2rem",
            display: "inline-block",
            textAlign: "left",
            margin: "0.4rem 0",
          }}
        >
          <InputBlock style={{ margin: "0.5rem 0" }}>
            <InputLabelStyled color="primary" required>
              審查結果
            </InputLabelStyled>
            <Select
              fullWidth
              displayEmpty
              required
              size="small"
              defaultValue={
                contractList?.userContracts[0].status === "REJECTED"
                  ? false
                  : ""
              }
              error={approvalError.pass}
              disabled={contractList?.userContracts[0].status === "REJECTED"}
              onChange={(e) =>
                handleChangeParams(
                  "pass",
                  e.target.value,
                  approvalParams,
                  setApprovalParams,
                  approvalError,
                  setApprovalError
                )
              }
            >
              {storefronts ? (
                Array.from(KYC_RESULT_OPTIONS.keys()).map((key, index) => (
                  <MenuItem
                    key={index}
                    value={KYC_RESULT_OPTIONS.get(key).value}
                  >
                    {KYC_RESULT_OPTIONS.get(key).label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
            <FormHelperText error={approvalError.pass}>
              {approvalError?.pass}
            </FormHelperText>
          </InputBlock>
          {!approvalParams.pass && (
            <InputBlock style={{ margin: "0.5rem 0" }}>
              <InputLabelStyled color="primary">未通過原因</InputLabelStyled>
              <OutlinedInput
                fullWidth
                type="text"
                multiline
                size="small"
                rows={5}
                disabled={contractList?.userContracts[0].status === "REJECTED"}
                defaultValue={contractList?.userContracts[0].rejectReason}
                onChange={(e) =>
                  handleChangeParams(
                    "rejectReason",
                    e.target.value,
                    approvalParams,
                    setApprovalParams,
                    approvalError,
                    setApprovalError
                  )
                }
              />
            </InputBlock>
          )}
          {contractList?.userContracts[0].status === "IN_REVIEW" && (
            <MemberApplicationControlBlock>
              <LoadingButton
                variant="contained"
                size="small"
                color="primary"
                onClick={handleGoBack}
              >
                取消
              </LoadingButton>
              <LoadingButton
                variant="contained"
                size="small"
                color="primary"
                loading={contractApprovalLoading}
                onClick={handleConfirmApproval}
              >
                送出
              </LoadingButton>
            </MemberApplicationControlBlock>
          )}
        </Card>
      )}
      {userProgressStatus && (
        <Card
          variant="outlined"
          color="primary"
          sx={{
            padding: "2rem",
            display: "inline-block",
            textAlign: "left",
            margin: "0.4rem 0",
          }}
        >
          {userProgressStatus === "待預約" ? (
            <>
              <Typography
                marginBottom="1.5rem"
                color="primary"
                variant="h4"
                align="left"
              >
                預約取貨
              </Typography>
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled required>取貨時間</InputLabelStyled>
                <OutlinedInput
                  fullWidth
                  size="small"
                  type="datetime-local"
                  required
                  onChange={(e) =>
                    handleChangeParams(
                      "pickUpMillis",
                      new Date(e.target.value).valueOf(),
                      appointmentParams,
                      setAppointmentParams,
                      appointmentError,
                      setAppointmentError
                    )
                  }
                  error={appointmentError.pickUpMillis}
                />
                <FormHelperText error={appointmentError.pickUpMillis}>
                  {appointmentError.pickUpMillis}
                </FormHelperText>
              </InputBlock>

              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled color="primary" required>
                  門市
                </InputLabelStyled>
                <Select
                  fullWidth
                  displayEmpty
                  required
                  size="small"
                  error={appointmentError.storefrontId}
                  onChange={(e) =>
                    handleChangeParams(
                      "storefrontId",
                      e.target.value,
                      appointmentParams,
                      setAppointmentParams,
                      appointmentError,
                      setAppointmentError
                    )
                  }
                >
                  {storefronts ? (
                    storefronts.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}></MenuItem>
                  )}
                </Select>
                <FormHelperText error={appointmentError.storefrontId}>
                  {appointmentError?.storefrontId}
                </FormHelperText>
              </InputBlock>
              <MemberApplicationControlBlock>
                <LoadingButton
                  variant="contained"
                  size="small"
                  color="primary"
                  loading={contractAppointmentLoading}
                  onClick={handleConfirmAppointment}
                >
                  儲存
                </LoadingButton>
              </MemberApplicationControlBlock>
            </>
          ) : (
            <>
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled required>取貨時間</InputLabelStyled>
                <OutlinedInput
                  fullWidth
                  size="small"
                  type="text"
                  required
                  disabled
                  value={
                    contractList?.userContracts[0]?.pickUpMillis
                      ? new Date(
                          contractList?.userContracts[0]?.pickUpMillis
                        ).toLocaleString()
                      : ""
                    //1695726000000
                    //1695726000000
                  }
                />
              </InputBlock>
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled color="primary" required>
                  門市
                </InputLabelStyled>
                <OutlinedInput
                  fullWidth
                  size="small"
                  type="text"
                  required
                  disabled
                  value={
                    contractList?.userContracts[0]?.storefrontId
                      ? getStorefrontName(
                          contractList?.userContracts[0]?.storefrontId
                        )
                      : ""
                  }
                />
                {userProgressStatus === "待取貨" && (
                  <MemberApplicationControlBlock>
                    <LoadingButton
                      variant="contained"
                      size="small"
                      color="primary"
                      loading={contractAppointmentLoading}
                      onClick={() => setShowEditAppointmentDialog(true)}
                    >
                      編輯
                    </LoadingButton>
                  </MemberApplicationControlBlock>
                )}
              </InputBlock>
            </>
          )}
        </Card>
      )}

      {(userProgressStatus === "待取貨" || userProgressStatus === "已簽約") && (
        <Card
          variant="outlined"
          color="primary"
          sx={{
            padding: "2rem",
            display: "inline-block",
            textAlign: "left",
            margin: "0.4rem 0",
          }}
        >
          <Typography color="primary" variant="h4" align="left">
            門市簽約
          </Typography>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: "1rem" }}
            onClick={() => setShowContractFormationDialog(true)}
          >
            {userProgressStatus === "待取貨" ? "成立合約" : "查看合約"}
          </TextButton>
        </Card>
      )}

      <ZoomiDialogStyled minWidth="25rem" open={showContractCancelDialog}>
        <DialogTitleStyled color="primary" variant="h4">
          取消申辦
        </DialogTitleStyled>

        <DialogContentStyled>
          <Typography color="primary" variant="h5" marginBottom={"1.5rem"}>
            請問是否取消以下會員的申辦？
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            帳號： {memberList?.userDtos[0].email}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            姓名： {memberList?.userDtos[0].name}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            方案： {contractList?.userContracts[0].originalContractName}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            手機型號：
            {`${contractList?.userContracts[0].originalPhoneType.phoneName}(${contractList?.userContracts[0].originalPhoneType.phoneColor}/${contractList?.userContracts[0].originalPhoneType.phoneCapacity})`}
          </Typography>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowContractCancelDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleConfirmContractCancel(() =>
                setShowContractCancelDialog(false)
              )
            }
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>

      <ZoomiDialogStyled open={showContractFormationDialog}>
        <DialogTitleStyled color="primary" variant="h4">
          {userProgressStatus === "待取貨" ? "成立合約" : "查看合約"}
        </DialogTitleStyled>

        <InputBlock>
          <InputLabelStyled required>方案</InputLabelStyled>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) =>
              handleChangeParams(
                "contractName",
                e.target.value,
                formationParams,
                setFormationParams,
                formationError,
                setFormationError
              )
            }
            defaultValue={formationParams?.contractName || ""}
          >
            <FormControlLabel
              value="租購"
              control={<Radio size="small" />}
              label="租購"
              disabled={userProgressStatus === "已簽約"}
            />
            <FormControlLabel
              value="舊手機換購"
              control={<Radio size="small" />}
              label="舊手機換購"
              disabled={userProgressStatus === "已簽約"}
            />
          </RadioGroup>
          <FormHelperText error={formationError.contractName}>
            {formationError.contractName}
          </FormHelperText>
        </InputBlock>

        {formationParams?.contractName === "舊手機換購" && (
          <InputBlock style={{ margin: "0.5rem 0" }}>
            <InputLabelStyled required>舊機折抵金額</InputLabelStyled>
            <OutlinedInput
              fullWidth
              size="small"
              type="number"
              required
              disabled={userProgressStatus === "已簽約"}
              defaultValue={
                formationParams?.discountAmount
                  ? formationParams?.discountAmount
                  : 0
              }
              onChange={(e) =>
                handleChangeParams(
                  "discountAmount",
                  e.target.value,
                  formationParams,
                  setFormationParams,
                  formationError,
                  setFormationError
                )
              }
              error={formationError.discountAmount}
            />
            <FormHelperText error={formationError.discountAmount}>
              {formationError.discountAmount}
            </FormHelperText>
          </InputBlock>
        )}

        {userProgressStatus === "已簽約" ? (
          <>
            <InputBlock style={{ margin: "0.5rem 0" }}>
              <InputLabelStyled color="primary" required>
                手機型號
              </InputLabelStyled>
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                required
                disabled
                value={contractList?.userContracts[0]?.phoneType.phoneName}
              />
            </InputBlock>
            <InputBlock style={{ margin: "0.5rem 0" }}>
              <InputLabelStyled color="primary" required>
                顏色
              </InputLabelStyled>
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                required
                disabled
                value={contractList?.userContracts[0]?.phoneType.phoneColor}
              />
            </InputBlock>
            <InputBlock style={{ margin: "0.5rem 0" }}>
              <InputLabelStyled color="primary" required>
                容量
              </InputLabelStyled>
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                required
                disabled
                value={`${
                  contractList?.userContracts[0]?.phoneType.phoneCapacity
                }/ 月租 ${formatMoney(
                  contractList?.userContracts[0]?.monthlyPrice
                )}`}
              />
            </InputBlock>
          </>
        ) : (
          <>
            {phoneNameList && (
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled color="primary" required>
                  手機型號
                </InputLabelStyled>
                <Select
                  fullWidth
                  displayEmpty
                  required
                  size="small"
                  defaultValue={
                    selectedPhoneType.name ? selectedPhoneType.name : ""
                  }
                  error={formationError.phoneTypeId}
                  onChange={(e) => handleSelectPhoneTye("name", e.target.value)}
                >
                  {phoneNameList ? (
                    phoneNameList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}></MenuItem>
                  )}
                </Select>
                <FormHelperText error={formationError.phoneTypeId}>
                  {formationError.phoneTypeId}
                </FormHelperText>
              </InputBlock>
            )}
            {selectedPhoneType.name && phoneColorList && (
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled color="primary" required>
                  顏色
                </InputLabelStyled>
                <Select
                  fullWidth
                  required
                  size="small"
                  error={formationError.phoneTypeId}
                  defaultValue={
                    selectedPhoneType.color ? selectedPhoneType.color : ""
                  }
                  disabled={userProgressStatus === "已簽約"}
                  onChange={(e) =>
                    handleSelectPhoneTye("color", e.target.value)
                  }
                >
                  {phoneColorList ? (
                    phoneColorList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}></MenuItem>
                  )}
                </Select>
                <FormHelperText error={formationError.phoneTypeId}>
                  {formationError.phoneTypeId}
                </FormHelperText>
              </InputBlock>
            )}
            {selectedPhoneType.color && phoneCapacityList && (
              <InputBlock style={{ margin: "0.5rem 0" }}>
                <InputLabelStyled color="primary" required>
                  容量
                </InputLabelStyled>
                <Select
                  fullWidth
                  required
                  size="small"
                  defaultValue={
                    selectedPhoneType.capacity ? selectedPhoneType.capacity : ""
                  }
                  disabled={userProgressStatus === "已簽約"}
                  error={formationError.phoneTypeId}
                  onChange={(e) =>
                    handleSelectPhoneTye("capacity", e.target.value)
                  }
                >
                  {phoneCapacityList ? (
                    phoneCapacityList.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}></MenuItem>
                  )}
                </Select>
                <FormHelperText error={formationError.phoneTypeId}>
                  {formationError.phoneTypeId}
                </FormHelperText>
              </InputBlock>
            )}
          </>
        )}

        <InputBlock style={{ margin: "0.5rem 0" }}>
          <InputLabelStyled required>IMEI</InputLabelStyled>
          <OutlinedInput
            fullWidth
            size="small"
            type="text"
            required
            defaultValue={
              formationParams?.phoneImei ? formationParams.phoneImei : ""
            }
            disabled={userProgressStatus === "已簽約"}
            onChange={(e) =>
              handleChangeParams(
                "phoneImei",
                e.target.value,
                formationParams,
                setFormationParams,
                formationError,
                setFormationError
              )
            }
            error={formationError.phoneImei}
          />
          <FormHelperText error={formationError?.phoneImei}>
            {formationError?.phoneImei}
          </FormHelperText>
        </InputBlock>

        <InputBlock style={{ margin: "0.5rem 0" }}>
          <InputLabelStyled required>合約起始日期</InputLabelStyled>
          {userProgressStatus === "已簽約" ? (
            <OutlinedInput
              fullWidth
              size="small"
              type="Text"
              required
              disabled
              value={
                formationParams?.contractStartMillis
                  ? formatTimestamp(formationParams?.contractStartMillis)
                  : ""
              }
            />
          ) : (
            <OutlinedInput
              fullWidth
              size="small"
              type="date"
              required
              disabled={userProgressStatus === "已簽約"}
              onChange={(e) => {
                return handleChangeParams(
                  "contractStartMillis",
                  new Date(e.target.value).valueOf(),
                  formationParams,
                  setFormationParams,
                  formationError,
                  setFormationError
                );
              }}
              error={formationError.contractStartMillis}
            />
          )}

          <FormHelperText error={formationError?.contractStartMillis}>
            {formationError?.contractStartMillis}
          </FormHelperText>
        </InputBlock>
        <InputBlock style={{ margin: "0.5rem 0" }}>
          <InputLabelStyled>保固</InputLabelStyled>
          <OutlinedInput
            fullWidth
            size="small"
            type="text"
            multiline
            rows={5}
            disabled={userProgressStatus === "已簽約"}
            defaultValue={formationParams?.detail ? formationParams.detail : ""}
            onChange={(e) =>
              handleChangeParams(
                "detail",
                e.target.value,
                formationParams,
                setFormationParams,
                formationError,
                setFormationError
              )
            }
          />
        </InputBlock>
        <MemberApplicationControlBlock>
          {userProgressStatus === "已簽約" ? (
            <LoadingButton
              variant="contained"
              size="small"
              color="primary"
              onClick={() => setShowContractFormationDialog(false)}
            >
              關閉
            </LoadingButton>
          ) : (
            <>
              <LoadingButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setShowContractFormationDialog(false)}
              >
                取消
              </LoadingButton>
              <LoadingButton
                variant="contained"
                size="small"
                color="primary"
                loading={contractApprovalLoading}
                onClick={() =>
                  handleConfirmFormation(setShowContractFormationDialog)
                }
              >
                送出
              </LoadingButton>
            </>
          )}
        </MemberApplicationControlBlock>
      </ZoomiDialogStyled>
      <ZoomiDialogStyled open={showEditAppointmentDialog}>
        <>
          <Typography
            marginBottom="1.5rem"
            color="primary"
            variant="h4"
            align="left"
          >
            預約取貨
          </Typography>
          <InputBlock style={{ margin: "0.5rem 0" }}>
            <InputLabelStyled required>取貨時間</InputLabelStyled>
            <OutlinedInput
              fullWidth
              size="small"
              type="datetime-local"
              required
              onChange={(e) =>
                handleChangeParams(
                  "pickUpMillis",
                  new Date(e.target.value).valueOf(),
                  appointmentParams,
                  setAppointmentParams,
                  appointmentError,
                  setAppointmentError
                )
              }
              error={appointmentError.pickUpMillis}
            />
            <FormHelperText error={appointmentError.pickUpMillis}>
              {appointmentError.pickUpMillis}
            </FormHelperText>
          </InputBlock>

          <InputBlock style={{ margin: "0.5rem 0" }}>
            <InputLabelStyled color="primary" required>
              門市
            </InputLabelStyled>
            <Select
              fullWidth
              displayEmpty
              required
              size="small"
              error={appointmentError.storefrontId}
              onChange={(e) =>
                handleChangeParams(
                  "storefrontId",
                  e.target.value,
                  appointmentParams,
                  setAppointmentParams,
                  appointmentError,
                  setAppointmentError
                )
              }
            >
              {storefronts ? (
                storefronts.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
            <FormHelperText error={appointmentError.storefrontId}>
              {appointmentError?.storefrontId}
            </FormHelperText>
          </InputBlock>
        </>
        <MemberApplicationControlBlock>
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setShowEditAppointmentDialog(false)}
          >
            取消
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            loading={contractAppointmentLoading}
            onClick={() =>
              handleConfirmAppointment(() =>
                setShowEditAppointmentDialog(false)
              )
            }
          >
            儲存
          </LoadingButton>
        </MemberApplicationControlBlock>
      </ZoomiDialogStyled>
    </div>
  );
};

export default BidVerification;
