import { styled, Card, InputLabel } from "@mui/material";

export const InformationCardStyled = styled(Card)`
  margin: 2rem 0;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const InformationBlockStyled = styled("div")`
  width: 20rem;
  text-align: left;
  padding: 0.75rem 1rem;
`;

export const InformationBlockTitleStyled = styled("div")`
  font-weight: bold;
`;

export const InformationBlockContentStyled = styled("div")`
  border-bottom: 1px dashed #000;
  opacity: 0.7;
`;

export const InputBlock = styled("div")`
  //   background-color: ${({ theme }) => theme.palette.background.secondary};
  margin: 1.5rem 0;
  width: 25rem;
`;

export const FlexBlock = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  width: 25rem;
`;
export const FlexBlockWithBorder = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 23rem;
  padding: 2rem 1rem;
  border: 1px solid #365E5B;
  border-radius: 6px;
`;



export const FlexInsideBlock = styled("div")`

`;
export const FlexInsideBlockWithFlex = styled("div")`
  display: flex;
  gap: 1rem;
`;

export const OutlinedBlock = styled("div")`
  width: 25rem;
`;

export const InputLabelStyled = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const SelectInputLabelStyled = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const MemberApplicationControlBlock = styled("div")`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-top: 3rem;
`;
