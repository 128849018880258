import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useNewsList from "../../../lib/api/useNewsList";
import {
  Typography,
  IconButton,
  OutlinedInput,
  Card,
  Button,
  FormHelperText,
  Box,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InputBlock, InputLabelStyled } from "../../../components/Card";
import { PAGE_SIZE, PAGE_START } from "../../../config/setting";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { UploadStyled } from "../../../components/Upload";
import useNewsCreate from "../../../lib/api/useNewsCreate";
import useNewsModify from "../../../lib/api/useNewsModify";
import useNewsModifyPicture from "../../../lib/api/useNewsModifyPicture";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkRequireParams } from "../../../lib/tools";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import "./../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DraftEditorBlockStyled } from "./styled";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../../config/enum";

const NewsEdit = () => {
  const { status, id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [newsCreate, newsCreateLoading, requestNewsCreate] = useNewsCreate();
  const [newsModify, newsModifyLoading, requestNewsModify] = useNewsModify();
  const [
    newsModifyPicture,
    newsModifyPictureLoading,
    requestNewsModifyPicture,
  ] = useNewsModifyPicture();
  const [newsList, newsListLoading, fetchNewsList] = useNewsList();
  const [newNewsParams, setNewNewsParams] = useState({
    title: null,
    content: null,
    status: "ENABLE",
  });
  const [newsError, setNewsError] = useState({});

  const [uploadFile, setUploadFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      fetchNewsList({
        page: PAGE_START,
        size: PAGE_SIZE,
        id: id,
      });
    }
  }, []);

  useEffect(() => {
    if (newsList) {
      setNewNewsParams({
        id: id,
        title: newsList?.newsDtos[0].title,
        content: newsList?.newsDtos[0].content,
        status: newsList?.newsDtos[0].status,
      });
      setPreviewFile(
        "data:image/jpeg;base64," + newsList?.newsDtos[0].picBase64
      );
      setEditorState(
        // EditorState.createWithContent(
        //   ContentState.createFromBlockArray(
        //     convertFromHTML(newsList?.newsDtos[0].content)
        //   )
        // )
        // EditorState.createWithContent(
        //   convertFromRaw(JSON.parse(newsList?.newsDtos[0].content))
        // )
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(newsList?.newsDtos[0].content).contentBlocks
          )
        )
      );
    }
  }, [newsList]);

  useEffect(() => {
    if (newsCreate) {
      if (uploadFile) {
        requestNewsModifyPicture({
          id: newsCreate.content.id,
          uploadFile: uploadFile,
        });
      } else {
        handleGoBack();
      }
    }
  }, [newsCreate]);

  useEffect(() => {
    if (newsModify) {
      if (uploadFile) {
        requestNewsModifyPicture({
          id: id,
          uploadFile: uploadFile,
        });
      } else {
        handleGoBack();
      }
    }
  }, [newsModify]);

  useEffect(() => {
    if (newsModifyPicture) {
      handleGoBack();
    }
  }, [newsModifyPicture]);

  const handleChangeParams = (key, value) => {
    if (newsError[key]) {
      setNewsError({ ...newsError, [key]: null });
    }

    setNewNewsParams({ ...newNewsParams, [key]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if (file && !file.type.match(/^image\//)) {
      enqueueSnackbar("檔案格式錯誤", {
        variant: MESSAGE_TYPE.ERROR,
      });
      return;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes) {
      enqueueSnackbar("檔案大小5MB以下", {
        variant: MESSAGE_TYPE.ERROR,
      });
      return;
    }

    setUploadFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewFile(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSaveNews = (status) => {
    if (checkRequireParams(newNewsParams, setNewsError)) {
      if (status === "create") {
        requestNewsCreate(newNewsParams);
      } else {
        requestNewsModify(newNewsParams);
      }
    }
  };

  const handelEditorChange = (content) => {
    setEditorState(content);

    const htmlText = draftToHtml(convertToRaw(content.getCurrentContent()));
    setNewNewsParams({
      ...newNewsParams,
      content: htmlText,
    });
  };

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        {status === "create" ? "新增" : "編輯"}最新消息
      </Typography>
      <Card sx={{ textAlign: "left", padding: "2rem" }}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            標題（建議字數：20）
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            value={newNewsParams?.title || ""}
            required
            inputProps={{ maxLength: 20 }}
            size="small"
            onChange={(e) => handleChangeParams("title", e.target.value)}
            error={!!newsError.title}
          />
          <FormHelperText error={!!newsError.title}>
            {newsError.title}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled>
            Banner（建議尺寸：800 x 524，5MB以下）
          </InputLabelStyled>
          <img src={previewFile} alt="" style={{ width: "100%" }} />
          <Box>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              點擊上傳
              <UploadStyled
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </InputBlock>
        <DraftEditorBlockStyled style={{ color: "black" }}>
          <InputLabelStyled color="primary" required>
            內容
          </InputLabelStyled>
          {/* <OutlinedInput
            fullWidth
            type="text"
            requiredx
            defaultValue={newNewsParams?.content || ""}
            size="small"
            onChange={(e) => handleChangeParams("content", e.target.value)}
            error={!!newsError.content}
            multiline
            rows={10}
          /> */}
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="zoomi-editor"
            onEditorStateChange={handelEditorChange}
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "embedded",
                "emoji",
                "image",
                "remove",
                "history",
              ],
              inline: { inDropdown: true },
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                ],
              },
            }}
          />
          {/* <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          /> */}
          <FormHelperText error={!!newsError.content}>
            {newsError.content}
          </FormHelperText>
        </DraftEditorBlockStyled>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            loading={newsCreateLoading || newsModifyLoading}
            onClick={handleGoBack}
          >
            取消
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            loading={newsCreateLoading || newsModifyLoading}
            onClick={() => handleSaveNews(status)}
          >
            儲存
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};

export default NewsEdit;
