import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "./requestHandler";
import { AuthContext } from "../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useAdminStoreCreate = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [adminStoreCreate, setAdminStoreCreate] = useState(null);
  const [adminStoreCreateLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestAdminStoreCreate = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/storefront/create",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(param),
        }
      );
      if (res.message === "Success") {
        setAdminStoreCreate(res);
        enqueueSnackbar("成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setAdminStoreCreate(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [adminStoreCreate, adminStoreCreateLoading, requestAdminStoreCreate],
    [adminStoreCreate, adminStoreCreateLoading, requestAdminStoreCreate]
  );
};

export default useAdminStoreCreate;
