import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  IconButton,
  TextField,
  OutlinedInput,
  Box,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import {
  ItemBlockStyled,
  MemberReviewStyled,
  ImageStyled,
  MemberReviewControlStyled,
} from "./styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  InformationCardStyled,
  InformationBlockStyled,
  InformationBlockTitleStyled,
  InformationBlockContentStyled,
  InputBlock,
  InputLabelStyled,
} from "../../../components/Card";
import {
  MEMBER_KYC_STATUS,
  GENDER_OPTIONS,
  KYC_RESULT_OPTIONS,
} from "../../../config/enum";
import useMemberKYCImage from "../../../lib/api/useMemberKYCImage";
import useMemberKYCApproval from "../../../lib/api/useMemberKYCApproval";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkRequireParams } from "../../../lib/tools";
import useMemberKYCList from "../../../lib/api/useMemberKYCList";
import { PAGE_SIZE, PAGE_START } from "../../../config/setting";

const detailConfig = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "帳號",
    key: "email",
  },
  {
    title: "手機號碼",
    key: "phoneNumber",
  },
  {
    title: "建立時間",
    key: "createMillis",
    renderCell: (item) => {
      return <>{item ? new Date(item.createMillis).toLocaleString() : ""}</>;
    },
  },
  {
    title: "姓名",
    key: "name",
  },
  {
    title: "性別",
    key: "gender",
    renderCell: (item) => {
      return <>{GENDER_OPTIONS.get(item.gender)?.label}</>;
    },
  },
  {
    title: "出生日期",
    key: "birthdayStr",
  },
  {
    title: "身分證字號",
    key: "idNumber",
  },
  {
    title: "職業",
    key: "jobZhName",
  },
  {
    title: "狀態",
    key: "status",
    renderCell: (item) => {
      return <>{MEMBER_KYC_STATUS.get(item.status)?.label}</>;
    },
  },
  {
    title: "通訊地址",
    key: "address",
    renderCell: (item) => {
      return <>{item.cityName + item.townName + item.address}</>;
    },
  },
];

const MemberReview = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [memberKYCImage, KYCImageLoading, fetchMemberKYCImage] =
    useMemberKYCImage();
  const [memberKYCApproval, approvalLoading, fetchMemberKYCApproval] =
    useMemberKYCApproval();
  const [readOnly, setReadOnly] = useState(false);
  const [memberKYCList, isLoading, fetchMemberKYCList] = useMemberKYCList();
  const [params, setParams] = useState({
    id: id,
    pass: false,
  });
  const [error, setError] = useState({
    pass: null,
    rejectReason: null,
  });

  useEffect(() => {
    if (!id) {
      handleGoBack();
      return;
    }
    fetchMemberKYCList({
      page: PAGE_START,
      size: PAGE_SIZE,
      id: id,
    });
  }, [memberKYCApproval]);

  useEffect(() => {
    if (memberKYCList && memberKYCList.userKycRecords.length > 0) {
      const member = memberKYCList.userKycRecords[0];
      setReadOnly(member.status !== "IN_REVIEW");
      fetchMemberKYCImage(id);
    }
  }, [memberKYCList]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({ ...error, [key]: null });
    }

    if (key === "pass") {
      if (value === true) {
        delete params.rejectReason;
        setParams({ ...params, [key]: true });
        return;
      } else {
        setParams({ ...params, [key]: false, rejectReason: null });
        return;
      }
    }

    if (!params.pass && key === "rejectReason") {
      setParams({ ...params, [key]: value });
    }
  };

  const handleRequestMemberKYCApproval = () => {
    // check params
    if (checkRequireParams(params, setError)) {
      fetchMemberKYCApproval(params);
    }
  };

  return (
    <MemberReviewStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        身份審核
      </Typography>
      {memberKYCList && memberKYCList.userKycRecords.length > 0 && (
        <>
          <InformationCardStyled variant="outlined" color="primary">
            <Typography
              color="primary"
              variant="h4"
              align="left"
              sx={{ width: "100%", marginBottom: "1rem" }}
            >
              會員資料
            </Typography>
            {detailConfig.map((item, index) => {
              return (
                <div key={index}>
                  <InformationBlockStyled>
                    <InformationBlockTitleStyled>
                      {item.title}
                    </InformationBlockTitleStyled>
                    <InformationBlockContentStyled>
                      {item.renderCell && item.key
                        ? item.renderCell(memberKYCList.userKycRecords[0])
                        : memberKYCList.userKycRecords[0][item.key]}
                    </InformationBlockContentStyled>
                  </InformationBlockStyled>
                </div>
              );
            })}
          </InformationCardStyled>
          <MemberReviewControlStyled sx={{ textAlign: "left" }}>
            <ItemBlockStyled>
              <Typography
                color="primary"
                variant="h5"
                align="left"
                sx={{ width: "100%", marginBottom: "1rem" }}
              >
                身份證正面
              </Typography>
              <ImageStyled
                alt="id"
                src={`data:image/jpeg;base64,${memberKYCImage?.idCardFrontBase64}`}
              />
            </ItemBlockStyled>
            <ItemBlockStyled>
              <Typography
                color="primary"
                variant="h5"
                align="left"
                sx={{ width: "100%", marginBottom: "1rem" }}
              >
                身份證反面
              </Typography>
              <ImageStyled
                alt="id"
                src={`data:image/jpeg;base64,${memberKYCImage?.idCardBackBase64}`}
              />
            </ItemBlockStyled>
            <ItemBlockStyled>
              <Typography
                color="primary"
                variant="h5"
                align="left"
                sx={{ width: "100%", marginBottom: "1rem" }}
              >
                電信帳單
              </Typography>
              <ImageStyled
                alt="id"
                src={`data:image/jpeg;base64,${memberKYCImage?.telecomBillBase64}`}
              />
            </ItemBlockStyled>
            <Box>
              <InputBlock>
                <InputLabelStyled color="primary" required>
                  審核結果
                </InputLabelStyled>

                <Select
                  fullWidth
                  select
                  required
                  size="small"
                  color="primary"
                  defaultValue={
                    KYC_RESULT_OPTIONS.get(
                      memberKYCList.userKycRecords[0]?.status
                    )?.value?.toString() || ""
                  }
                  // defaultValue={true}
                  disabled={readOnly}
                  error={!!error.pass}
                  onChange={(e) => handleChangeParams("pass", e.target.value)}
                >
                  {Array.from(KYC_RESULT_OPTIONS.keys()).map((key) => (
                    <MenuItem
                      key={key}
                      value={KYC_RESULT_OPTIONS.get(key).value}
                    >
                      {KYC_RESULT_OPTIONS.get(key).label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={error.pass}>
                  {error?.pass}
                </FormHelperText>
              </InputBlock>
              {!params.pass && (
                <InputBlock>
                  <InputLabelStyled color="primary">
                    未通過原因
                  </InputLabelStyled>
                  <OutlinedInput
                    fullWidth
                    type="text"
                    multiline
                    required
                    size="small"
                    rows={3}
                    disabled={readOnly}
                    error={error.rejectReason}
                    defaultValue={
                      memberKYCList.userKycRecords[0].rejectReason
                        ? memberKYCList.userKycRecords[0].rejectReason
                        : ""
                    }
                    onChange={(e) =>
                      handleChangeParams("rejectReason", e.target.value)
                    }
                  />{" "}
                  <FormHelperText error={error.rejectReason}>
                    {error?.rejectReason}
                  </FormHelperText>
                </InputBlock>
              )}
            </Box>
          </MemberReviewControlStyled>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "2rem",
        }}
      >
        <LoadingButton
          variant="contained"
          size="small"
          color="primary"
          sx={{}}
          loading={approvalLoading}
          onClick={handleGoBack}
        >
          {!readOnly ? "取消" : "返回"}
        </LoadingButton>
        {!readOnly && (
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            loading={approvalLoading}
            onClick={handleRequestMemberKYCApproval}
          >
            送出
          </LoadingButton>
        )}
      </Box>
    </MemberReviewStyled>
  );
};

export default MemberReview;
