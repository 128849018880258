import { Collapse, Typography, ListItemButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { useMatch } from "react-router";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  LinkOptionStyled,
  LinkText,
  MenuStyled,
  MenuCollapseContentStyled,
} from "./styled";
import { AuthContext } from "../../lib/context/authContext";
import { ROLE_TYPE, LOCAL_STORAGE_ROLE } from "../../config/enum";

const LinkOption = ({ label, to, activeOnlyWhenExact }) => {
  let match = useMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  const navigate = useNavigate();

  const handleGoToUrl = () => {
    navigate(to);
  };

  return (
    <LinkOptionStyled onClick={handleGoToUrl}>
      <LinkText active={match} variant="h5">
        {label}
      </LinkText>
    </LinkOptionStyled>
  );
};

const LinkMenu = () => {
  const [memberOpen, setMemberOpen] = useState(false);
  const role = localStorage.getItem(LOCAL_STORAGE_ROLE);

  return (
    <MenuStyled component="nav">
      <ListItemButton
        onClick={() => {
          setMemberOpen(!memberOpen);
        }}
      >
        <Typography variant="h5"> 會員管理</Typography>
        {memberOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={memberOpen} timeout="auto" unmountOnExit>
        <MenuCollapseContentStyled>
          <LinkOption
            label={"會員管理"}
            to={"/member-management"}
            activeOnlyWhenExact={true}
          />
          <LinkOption
            label={"實名認證"}
            to={"/member-verification"}
            activeOnlyWhenExact={true}
          />
        </MenuCollapseContentStyled>
      </Collapse>

      <LinkOption
        label={"申辦列表"}
        to={"/bid-list"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"合約管理"}
        to={"/contract-management"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"帳單列表"}
        to={"/bill-list"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"最新消息"}
        to={"/news-list"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"常見問題"}
        to={"/faq-list"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"商品管理"}
        to={"/product-management"}
        activeOnlyWhenExact={true}
      />

      <LinkOption
        label={"門市管理"}
        to={"/store-management"}
        activeOnlyWhenExact={true}
      />
      {role === ROLE_TYPE.MANAGER && (
        <LinkOption
          label={"使用者管理"}
          to={"/user-management"}
          activeOnlyWhenExact={true}
        />
      )}

      <LinkOption
        label={"個人中心"}
        to={"/user-center"}
        activeOnlyWhenExact={true}
      />
    </MenuStyled>
  );
};

export default LinkMenu;
