import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#365E5B",
    },
    secondary: { main: "#FFDF5A", contrastText: "#365E5B" },
    third: {
      main: "#FFDF5A",
      contrastText: "white",
    },
    background: {
      main: "white",
      secondary: "#faf9f4",
      dark: "#434343",
    },
    text: {
      primary: "#365E5B",
      secondary: "#acc6be",
    },
  },
  typography: {
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1rem",
    },
  },
  button: {
    boxShadow: "unset",
  },
});
