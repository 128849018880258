import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useNewsModify = () => {
  const [handleError] = useErrorHandler();
  const [newsModify, setNewsModify] = useState(null);
  const [newsModifyLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestNewsModify = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/news/modify",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(param),
        }
      );
      if (res.message === "Success") {
        setNewsModify(res);
        enqueueSnackbar("成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setNewsModify(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [newsModify, newsModifyLoading, requestNewsModify],
    [newsModify, newsModifyLoading, requestNewsModify]
  );
};

export default useNewsModify;
