import { useCallback, useMemo, useState, useContext } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useMemberList = () => {
  const [memberList, setMemberList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [handleError] = useErrorHandler();

  const fetchMemberList = useCallback(async (filterParams) => {
    try {
      setIsLoading(true);

      try {
        setIsLoading(true);

        const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

        const res = await requestHandler(
          API_URL.USER_INFO_LIST,
          API_METHOD.POST,
          auth,
          {
            body: JSON.stringify(filterParams),
          }
        );
        if (res.message === "Success") {
          setMemberList(res.content);
        } else {
          handleError(res?.code);
        }
      } catch (error) {
        setMemberList(null);
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      setMemberList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [memberList, isLoading, fetchMemberList],
    [memberList, isLoading, fetchMemberList]
  );
};

export default useMemberList;
