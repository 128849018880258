import { calcDateDiff } from "../lib/tools";
import {
  CONTRACT_STATUS_OPTIONS,
  BILL_PAYMENT_STATUS_OPTIONS,
  PROGRESS_STATUS_OPTIONS,
  REVIEW_STATUS_OPTIONS,
} from "./enum";

export const PAGE_SIZE = 10;
export const PAGE_START = 0;
export const CONTRACT_DURING_MONTH = 12;

export const DEFAULT_STATUS_LIST = [
  "ACTIVE",
  "EXPIRED",
  "TERMINATED",
  "EARLY_TERMINATED",
];

export const ContractStatus = (contract) => {
  if (contract.status === "ACTIVE") {
    const diffdays = calcDateDiff(contract.contractEndMillis, Date.now());

    if (diffdays < 30) {
      //即將到期
      return CONTRACT_STATUS_OPTIONS.get("AWAITING_APPOINTMENT");
    } else {
      //使用中
      return CONTRACT_STATUS_OPTIONS.get("ACTIVE");
    }

    //已到期
  } else if (contract.status === "EXPIRED") {
    return CONTRACT_STATUS_OPTIONS.get("EXPIRED");

    //已結束
  } else {
    return CONTRACT_STATUS_OPTIONS.get("TERMINATED");
  }
};

export const getBillPaymentStatus = (bill) => {
  if (!bill) return null;
  if (bill.status === "PAYMENT_IN_PROGRESS") {
    //待繳款
    return BILL_PAYMENT_STATUS_OPTIONS.get("PAYMENT_IN_PROGRESS");
  } else if (bill.status === "PAYMENT_IN_PROGRESS" && bill.isOverdue) {
    //逾期繳款
    return BILL_PAYMENT_STATUS_OPTIONS.get("PAYMENT_OVERDUE");

    //繳款成功
  } else if (bill.status === "PAYMENT_COMPLETED") {
    return BILL_PAYMENT_STATUS_OPTIONS.get("PAYMENT_COMPLETED");

    //待確認繳款
  } else {
    return BILL_PAYMENT_STATUS_OPTIONS.get("PAYMENT_CONFIRMING");
  }
};

//取得合約進度文字
export const getContractProgressText = (status) => {
  let output = null;
  Array.from(PROGRESS_STATUS_OPTIONS.keys()).forEach((key) => {
    if (PROGRESS_STATUS_OPTIONS.get(key).value.includes(status)) {
      output = PROGRESS_STATUS_OPTIONS.get(key).label;
      return;
    }
  });
  return output;
};

// 取得合約審核狀態文字
export const getContractStatusText = (status) => {
  let output = "-";

  Array.from(REVIEW_STATUS_OPTIONS.keys()).forEach((key) => {
    if (REVIEW_STATUS_OPTIONS.get(key).value.includes(status)) {
      output = REVIEW_STATUS_OPTIONS.get(key).label;
      return;
    }
  });
  return output;
};
