import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {StoreModifyStyled, StoreInputCard} from "./styled";
import {
  Typography,
  OutlinedInput,
  IconButton,
  MenuItem,
  FormHelperText,
  Select, InputAdornment,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {TextButton} from "../../../components/Button";
import {
  FlexBlock, FlexInsideBlock,
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock, OutlinedBlock,
} from "../../../components/Card";
import useJobList from "../../../lib/api/useJobList";
import useCityTownList from "../../../lib/api/useCityTownList";
import usePhoneTypeList from "../../../lib/api/usePhoneTypeList";
import useStorefrontList from "../../../lib/api/useStorefrontList";
import useContractCreate from "../../../lib/api/useContractCreate";
import {calcTimestampUTC, formatMoney} from "../../../lib/tools";
import {CONTRACT_DURING_MONTH} from "../../../config/setting";
import {ADMIN_ROLE, ADMIN_STATUS_MODIFY,STORE_STATUS} from "../../../config/enum";
import useAdminModify from "../../../lib/api/useAdminModify";
import useAdminCreate from "../../../lib/api/useAdminCreate";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const UserModify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let {user} = location.state;
  const [cityTownList, cityTownListLoading, fetchCityTownList] = useCityTownList();
  const [jobList, jobListLoading, fetchJobList] = useJobList();
  const [phoneTypeList, phoneTypeLoading, fetchPhoneTypeList] =
    usePhoneTypeList();
  const [storefronts, storefrontsLoading, fetchStorefront] =
    useStorefrontList();
  const [adminModify, adminModifyLoading, requestAdminModify] =
    useAdminModify();
  const [adminCreate, adminCreateLoading, requestAdminCreate] =
    useAdminCreate();
  const [
    contractCreateLoading,
    contractParams,
    setContractParams,
    contractCreateResult,
    postContractCreate,
  ] = useContractCreate();

  const [selectedPhoneType, setSelectedPhoneType] = useState({
    name: null,
    capacity: null,
    color: null,
  });

  const [selectedCityTown, setSelectedCityTown] = useState({
    id: null,
    cityName: null,
    postalCode: null,
    townName: null,
  });
  const [userForm, setUserForm] = useState({
    role: null,
    status: null,
    password: null,
    name: null,
    // storefrontId: null,
    // adminId: null,
  });
  const [storeModifyStatus, setStoreModifyStatus] = useState(null);
  const [cityOptions, setCityOptions] = useState(null);
  const [townOptions, setTownOptions] = useState(null);
  const [phoneNameList, setPhoneNameList] = useState(null);
  const [phoneCapacityList, setPhoneCapacityList] = useState(null);
  const [phoneColorList, setPhoneColorList] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  //     {
  //   "contractName": "<string>",
  //   "contractStartMillis": "<long>",
  //   "discountAmount": "<number>",
  //   "monthlyPrice": "<number>",
  //   "phoneImei": "<string>",
  //   "phoneTypeId": "<long>",
  //   "pickUpMillis": "<long>",
  //   "storefrontId": "<long>",
  //   "userId": "<long>",
  //   "durationMonth": "<integer>",
  //   "detail": "<string>"
  // }

  const [error, setError] = useState({});

  useEffect(() => {
    if (adminModify && adminModify.code === 0) {
      handleGoBack()
    }
  }, [adminModify]);

  useEffect(() => {
    if (adminCreate && adminCreate.code === 0) {
      handleGoBack()
    }
  }, [adminCreate]);

  useEffect(() => {
    fetchStorefront().then(r => {
    });
    if (!user) {
      setStoreModifyStatus("create");
    }else {
      setUserForm({
        adminId: user.id,
        role: user.role,
        status: user.status,
        password: user.password,
        name: user.name,
        storefrontId: user.storefrontId,
      });
    }
  }, []);

  useEffect(() => {
  }, [contractParams]);

  useEffect(() => {
    initStorefront();
  }, [storefronts]);

  useEffect(() => {
    initCityTownSelectedOptions();
  }, [cityTownList]);

  useEffect(() => {
    console.log(userForm.cityName)
    getCityTownOptions();
  }, [userForm.cityName]);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({...error, [key]: null});
    }
    setUserForm({...userForm, [key]: value});
    // setContractParams({...contractParams, [key]: value});
  };

  const checkParams = (userForm) => {
    let pass = true;
    let newError = {...userForm};

    for (let key in userForm) {
      if (userForm[key] === null || userForm[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
      if (key === 'passwordCheck'){
        if (userForm[key] !== userForm['password']) {
          pass = false;
          newError[key] = "密碼不一致";
        } else {
          newError[key] = null;
        }
      }
    }
    console.log(newError)
    setError(newError);
    return pass;
  };

  const handleCreateMemberApplication = () => {
    // setContractParams(() => ({ ...contractParams, pickUpMillis: Date.now() }));
    if (checkParams(userForm)) {
      if (storeModifyStatus === "create") {
        requestAdminCreate(
          {
            email: userForm.email,
            role: userForm.role,
            status: userForm.status,
            password: userForm.password,
            name: userForm.name,
            storefrontId: userForm.storefrontId

          });
      } else {
        requestAdminModify(
          {
            ...userForm,
          });
      }
    }
  };

  const initStorefront = () => {
    if (storefronts) {
      // setSelectedPhoneType({...selectedPhoneType});
      // const nameList = getPhoneOptionsList("name", phoneTypeList);
      // setPhoneNameList(nameList);
    }
  };

  const getCityTownOptions = () => {
    if (cityTownList) {
      setCityOptions([...new Set(cityTownList.map((item) => item.cityName))]);
      setTownOptions([...new Set(cityTownList.filter((el) => userForm.cityName === el.cityName).map((item) => item.townName))]);
    }
  }

  const initCityTownSelectedOptions = () => {
    if (cityTownList) {
      setSelectedCityTown({...selectedCityTown});
      const nameList = getCityTownOptions();
      console.log(nameList)
      // setPhoneNameList(nameList);
    }
  }

  return (
    <StoreModifyStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit"/>
        </IconButton>
        {`${storeModifyStatus ? "新增" : "修改"}使用者`}
      </Typography>
      <StoreInputCard color="primary" sx={{textAlign: "left"}}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            使用者名稱
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            defaultValue={user?.name || ""}
            error={error.name}
            size="small"
            onChange={(e) => handleChangeParams("name", e.target.value)}
          />
          <FormHelperText error={error.name}>
            {error?.name}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>帳號（信箱）</InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            size="small"
            disabled={storeModifyStatus !== "create"}
            defaultValue={user?.email}
            error={error.email}
            onChange={(e) => handleChangeParams("email", e.target.value)}
          />
          <FormHelperText error={error.email}>
            {error?.email}
          </FormHelperText>
        </InputBlock>
        <InputBlock style={{ margin: "0.5rem 0" }}>
          <InputLabelStyled color="primary" required>
            門市
          </InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={user?.storefrontId || ""}
            error={error.storefrontId}
            onChange={(e) =>
              handleChangeParams(
                "storefrontId",
                e.target.value
              )
            }
          >
            {storefronts ? (
              storefronts.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.storefrontId}>
            {error?.storefrontId}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>角色</InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={user?.role || ""}
            error={error.role}
            onChange={(e) => handleChangeParams("role", e.target.value)}
          >
            {ADMIN_ROLE ? (
              Array.from(ADMIN_ROLE.keys()).map((option, index) => (
                <MenuItem key={index} value={option}>
                  {ADMIN_ROLE.get(option)?.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.role}>
            {error?.role}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>帳號狀態</InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={user?.status || ""}
            error={error.status}
            onChange={(e) => handleChangeParams("status", e.target.value)}
          >
            {ADMIN_STATUS_MODIFY ? (
              Array.from(ADMIN_STATUS_MODIFY.keys()).map((option, index) => (
                <MenuItem key={index} value={option}>
                  {ADMIN_STATUS_MODIFY.get(option)?.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.status}>
            {error?.status}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled color="primary" >
            密碼
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type={showPassword ? "text" : "password"}
            error={error.password}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => handleChangeParams("password", e.target.value)}
          />
          <FormHelperText error={error.password}>
            {error?.password}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled color="primary" >
            確認密碼
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type={showPassword ? "text" : "password"}
            error={error.passwordCheck}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => handleChangeParams("passwordCheck", e.target.value)}
          />
          <FormHelperText error={error.passwordCheck}>
            {error?.passwordCheck}
          </FormHelperText>
        </InputBlock>
        <MemberApplicationControlBlock>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleGoBack}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleCreateMemberApplication}
          >
            送出
          </TextButton>
        </MemberApplicationControlBlock>
      </StoreInputCard>
    </StoreModifyStyled>
  );
};

export default UserModify;
