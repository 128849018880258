import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const ZoomiDialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    padding: 2rem;
    min-width: ${(props) => props.minWidth};
  }
`;

export const DialogTitleStyled = styled(DialogTitle)`
  text-align: center;
`;

export const DialogContentStyled = styled(DialogContent)``;

export const DialogContentActionStyled = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const FooterButtonBar = styled("div")`
  display: flex;
`;
