import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";

const useFaqList = () => {
  const [handleError] = useErrorHandler();
  const [faqList, setFaqList] = useState(null);
  const [faqListLoading, setIsLoading] = useState(false);

  const fetchFaqList = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/faq/list",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setFaqList(res.content);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setFaqList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [faqList, faqListLoading, fetchFaqList],
    [faqList, faqListLoading, fetchFaqList]
  );
};

export default useFaqList;
