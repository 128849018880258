import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "../api/requestHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useJobList = () => {
  const [handleError] = useErrorHandler();
  const [jobList, setJobList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchJobList = useCallback(async () => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/constant/job/list",
        API_METHOD.GET,
        auth
      );
      if (res.message === "Success") {
        let jobs = res.content.jobs;
        jobs.unshift({
          id: "ALL",
          chName: "全部",
        });
        setJobList(jobs);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setJobList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [jobList, isLoading, fetchJobList],
    [jobList, isLoading, fetchJobList]
  );
};

export default useJobList;
