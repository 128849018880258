import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Button,
  styled,
  Typography,
  Container,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { requestHandler } from "../../lib/api/requestHandler";
import { ReactComponent as Logo } from "../../assets/zoomi-logo.svg";
import { AuthContext } from "./../../lib/context/authContext";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";

const LoginStyled = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
`;

const StyledButton = styled(Button)``;

const Login = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [account, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountError, setAccountError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate("/member-management/");
    }
  }, [auth.isLoggedIn]);

  const handleLogin = async () => {
    if (!account) {
      setAccountError("請輸入帳號");
    }
    if (!password) {
      setPasswordError("請輸入密碼");
    }
    if (account && password) {
      // 處理登入邏輯
      try {
        const res = await auth.loginHandler(account, password);
      } catch (error) {
        // enqueueSnackbar(error, { variant: MESSAGE_TYPE.ERROR });
      }
    }
  };

  const handleInputChange = (value, setInput) => {
    if (accountError) setAccountError(null);
    if (passwordError) setPasswordError(null);
    // 使用正則表達式去除空白
    value = `${value}`;
    setInput(value.replace(/\s/g, ""));
    // const sanitizedValue = value
    //   .replace(/[^a-zA-Z0-9_]/g, "")
    //   .replace(/\s/g, "");
    // setInput(sanitizedValue);
  };

  const handleForgetPassword = () => {};

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LoginStyled>
      <Logo style={{ width: "150px", height: "135px" }} />
      <Typography
        color="primary"
        variant="h4"
        align="center"
        sx={{
          marginTop: "2rem",
          fontWeight: "bold",
        }}
      >
        租咪ZOOMI管理後台
      </Typography>
      <Typography
        color="primary"
        variant="h5"
        align="center"
        sx={{
          marginTop: "3rem",
          fontWeight: "bold",
        }}
      >
        {forgetPassword ? "忘記密碼" : "登入"}
      </Typography>
      {/* <TextField
        required
        error={accountError}
        label="帳號"
        variant="outlined"
        type="account"
        value={account}
        color="primary"
        size="small"
        helperText={accountError ? "請輸入帳號" : ""}
        onChange={(e) => {
          handleInputChange(e.target.value, setEmail);
          setAccountError(false);
        }}
        sx={{
          minWidth: "25rem",
          marginTop: "1rem",
        }}
      /> */}
      {/* <TextField
        required
        error={passwordError}
        label="密碼"
        id="filled-start-adornment"
        variant="outlined"
        type="password"
        value={password}
        color="primary"
        size="small"
        helperText={accountError ? "請輸入密碼" : ""}
        onChange={(e) => {
          handleInputChange(e.target.value, setPassword);
          setPasswordError(false);
        }}
        sx={{
          minWidth: "25rem",
          marginTop: "1rem",
        }}
      > */}
      {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="filled"> */}
      <InputLabel
        sx={{ marginTop: "1rem", textAlign: "left", minWidth: "25rem" }}
        htmlFor="outlined-adornment-password"
      >
        帳號
      </InputLabel>
      <OutlinedInput
        type={"text"}
        color="primary"
        size="small"
        sx={{ minWidth: "25rem" }}
        error={accountError}
        onChange={(e) => {
          handleInputChange(e.target.value, setEmail);
        }}
        endAdornment={
          <InputAdornment position="end">
            {forgetPassword ? (
              <Typography
                color="primary"
                variant="body1"
                sx={{ cursor: "pointer" }}
              >
                發送驗證碼
              </Typography>
            ) : null}
          </InputAdornment>
        }
      />
      <InputLabel
        error={!!accountError}
        sx={{ textAlign: "left", minWidth: "25rem" }}
      >
        {accountError ?? ""}
      </InputLabel>

      <InputLabel
        sx={{ marginTop: "1rem", textAlign: "left", minWidth: "25rem" }}
        htmlFor="outlined-adornment-password"
      >
        密碼
      </InputLabel>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        color="primary"
        size="small"
        sx={{ minWidth: "25rem" }}
        error={passwordError}
        onChange={(e) => {
          handleInputChange(e.target.value, setPassword);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <InputLabel
        error={!!passwordError}
        sx={{ textAlign: "left", minWidth: "25rem" }}
      >
        {passwordError ?? ""}
      </InputLabel>
      {/* </FormControl> */}
      {/* </TextField> */}
      {/* <Typography
        color="primary"
        variant="body1"
        align="right"
        sx={{
          minWidth: "25rem",
          margin: " 0.5rem 0 1rem 0",
          cursor: "pointer",
        }}
        onClick={() => setForgetPassword(!forgetPassword)}
      >
        忘記密碼
      </Typography> */}
      <StyledButton
        variant="contained"
        color="primary"
        size="normal"
        sx={{
          marginTop: " 1rem ",
        }}
        onClick={handleLogin}
      >
        登入
      </StyledButton>
    </LoginStyled>
  );
};

export default Login;
