import { useCallback, useContext, useMemo, useState } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { useSnackbar } from "notistack";
import { MESSAGE_TYPE } from "../../config/enum";

const useNewsModifyPicture = () => {
  const [handleError] = useErrorHandler();
  const [newsModifyPicture, setNewsModifyPicture] = useState(null);
  const [newsModifyPictureLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestNewsModifyPicture = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      let headers = new Headers();
      headers.append("Accept", "*/*");
      headers.append("ADMIN-LOGIN-TOKEN", auth || "");

      let formData = new FormData();
      // formData.append("pic", new Blob([param.uploadFile]));
      formData.append("pic", param.uploadFile, param.uploadFile.name);

      // const boundary = "----" + new Date().getTime();
      // let requestBody = `--${boundary}\r\n`;
      // requestBody += `Content-Disposition: form-data; name="pic"; "\r\n`;
      // requestBody += "Content-Type: multipart/form-data\r\n\r\n";
      // requestBody += new Blob([param.uploadFile]).text();
      // requestBody += param.uploadFile;
      // requestBody += `\r\n--${boundary}--`;

      const res = await fetch(
        "/zoomi/admin/news/modify/pic" + `?id=${param.id}`,
        {
          headers: headers,
          method: API_METHOD.POST,
          body: formData,
          redirect: "follow",
        }
      );
      const resJson = await res.json();

      if (resJson.message === "Success") {
        setNewsModifyPicture(resJson);
        enqueueSnackbar("圖片更新成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(resJson?.code);
      }
    } catch (error) {
      setNewsModifyPicture(null);
      console.error("Error fetching data:", error);
      enqueueSnackbar("圖片更新失敗", { variant: MESSAGE_TYPE.ERROR });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [
      newsModifyPicture,
      newsModifyPictureLoading,
      requestNewsModifyPicture,
    ],
    [newsModifyPicture, newsModifyPictureLoading, requestNewsModifyPicture]
  );
};

export default useNewsModifyPicture;
