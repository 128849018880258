import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {StoreModifyStyled, StoreInputCard} from "./styled";
import {
  Typography,
  OutlinedInput,
  IconButton,
  MenuItem,
  FormHelperText,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {TextButton} from "../../../components/Button";
import {
  FlexBlock, FlexBlockWithBorder, FlexInsideBlock, FlexInsideBlockWithFlex,
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock, OutlinedBlock,
} from "../../../components/Card";
import useJobList from "../../../lib/api/useJobList";
import useCityTownList from "../../../lib/api/useCityTownList";
import usePhoneTypeList from "../../../lib/api/usePhoneTypeList";
import useStorefrontList from "../../../lib/api/useStorefrontList";
import useContractCreate from "../../../lib/api/useContractCreate";
import useAdminStoreModify from "../../../lib/api/useAdminStoreModify";
import useAdminPhoneTypeCreate from "../../../lib/api/useAdminPhoneTypeCreate";
import {
  InformationCardStyled,
} from "../../../components/Card";
import {MESSAGE_TYPE, PHONE_CAPACITY} from "../../../config/enum";
import {useSnackbar} from "notistack";


const ProductCreate = () => {
  const {enqueueSnackbar} = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [adminPhoneTypeCreate, adminPhoneTypeCreateLoading, requestAdminPhoneTypeCreate] =
    useAdminPhoneTypeCreate();

  const [capacityList, setCapacityList] = useState([
    {
      capacity: null,
      monthlyPrice: null,
      unit: "GB",
    },
    {
      capacity: null,
      monthlyPrice: null,
      unit: "GB",
    },
    {
      capacity: null,
      monthlyPrice: null,
      unit: "GB",
    },
  ]);
  const [productForm, setProductForm] = useState({
    name: null,
    color: null,
    capacity: null,
    monthlyPrice: null,
  });

  const [apiCount, setApiCount] = useState(0);

  const [error, setError] = useState({});


  useEffect(() => {
    if (adminPhoneTypeCreate && adminPhoneTypeCreate.code === 0) {
      navigate('/product-management', { replace: true });
    }
  }, [adminPhoneTypeCreate]);

  useEffect(() => {
    if (adminPhoneTypeCreate && adminPhoneTypeCreate.code === 0) {
      setApiCount(apiCount + 1);
      const capacitySize = capacityList.filter(item => {
        return item.capacity && item.monthlyPrice;
      }).length;
      if (apiCount === capacitySize-1) {
        enqueueSnackbar("新增成功", {variant: MESSAGE_TYPE.SUCCESS});
        navigate(-1);
      }
    }
  }, [adminPhoneTypeCreate]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value, index) => {
    if (error[key]) {
      setError({...error, [key]: null});
    }
    if(key === 'unit'){
      capacityList[index][key] = value
      setCapacityList(capacityList);
    }
    setProductForm({...productForm, [key]: value});
  };

  const handleChangeCapacityParams = (key, index, value) => {
    if (error[key]) {
      setError({...error, [key]: null});
    }
    capacityList[index][key] = value;
    setCapacityList(capacityList);
  }

  const checkParams = (productForm) => {
    let pass = true;
    let newError = {...productForm};

    let checkCapacity = capacityList.some(item => {
      return item.capacity && item.monthlyPrice;
    });
    if (!checkCapacity) {
      pass = false;
      enqueueSnackbar("請至少填寫一組容量與月租費", {variant: MESSAGE_TYPE.ERROR});
    }
    for (let key in productForm) {
      if ((productForm[key] === null || productForm[key] === "") && key !== 'capacity' && key !== 'monthlyPrice') {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setError(newError);
    return pass;
  };

  const handleCreateMemberApplication = () => {
    // setContractParams(() => ({ ...contractParams, pickUpMillis: Date.now() }));
    if (checkParams(productForm)) {
      capacityList.forEach((item, index) => {
        console.log(item)
          if (item.capacity && item.monthlyPrice) {
            productForm[`capacity`] = `${item.capacity}${item.unit}`;
            productForm[`monthlyPrice`] = item.monthlyPrice;
            requestAdminPhoneTypeCreate(
              {
                ...productForm,
              });
          }
        }
      )
    }
  };

  return (
    <StoreModifyStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit"/>
        </IconButton>
        {`新增商品`}
      </Typography>
      <StoreInputCard color="primary" sx={{textAlign: "left"}}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            手機機型
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            size="small"
            error={error.name}
            onChange={(e) => handleChangeParams("name", e.target.value)}
          />
          <FormHelperText error={error.name}>
            {error.name}
          </FormHelperText>
        </InputBlock>
        <InformationCardStyled
          sx={{margin: "0.4rem 0", width: "21rem", gap: "1rem"}}
          variant="outlined"
          color="primary"
        >
          <FlexInsideBlock sx={{width: '100%'}}>
            <InputLabelStyled color="primary" required>
              顏色
            </InputLabelStyled>
            <OutlinedInput
              fullWidth
              type="text"
              required
              size="small"
              error={error.color}
              onChange={(e) => handleChangeParams("color", e.target.value)}
            />
            <FormHelperText error={error.color}>
              {error.color}
            </FormHelperText>
          </FlexInsideBlock>
          {
            capacityList && capacityList.map((item, index) => {
                return (
                  <FlexInsideBlockWithFlex>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      required
                      placeholder="容量"
                      endAdornment={<div position="end">
                        <Select
                          fullWidth
                          displayEmpty
                          required
                          size="small"
                          defaultValue={"GB"}
                          sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                              border: "none",
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: "none",
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: "none",
                            },
                            '.MuiSvgIcon-root ': {
                              fill: "unset !important",
                            }
                          }}
                          onChange={(e) => handleChangeParams("unit", e.target.value, index)}
                        >
                          {PHONE_CAPACITY ? (
                            Array.from(PHONE_CAPACITY.keys()).map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {PHONE_CAPACITY.get(option)?.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={0} value={0}></MenuItem>
                          )}
                        </Select>
                      </div>}
                      size="small"
                      onChange={(e) => handleChangeCapacityParams("capacity", index, e.target.value)}
                    />
                    <OutlinedInput
                      fullWidth
                      type="number"
                      required
                      placeholder="月租費"
                      size="small"
                      onChange={(e) => handleChangeCapacityParams("monthlyPrice", index, e.target.value)}
                    />
                  </FlexInsideBlockWithFlex>
                )
              }
            )
          }
        </InformationCardStyled>

        <MemberApplicationControlBlock>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleGoBack}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleCreateMemberApplication}
          >
            送出
          </TextButton>
        </MemberApplicationControlBlock>
      </StoreInputCard>
    </StoreModifyStyled>
  );
};

export default ProductCreate;
