import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {StoreModifyStyled, StoreInputCard} from "./styled";
import {
  Typography,
  OutlinedInput,
  IconButton,
  MenuItem,
  FormHelperText,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {TextButton} from "../../../components/Button";
import {
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock, OutlinedBlock,
} from "../../../components/Card";
import {PHONE_CAPACITY, PRODUCT_STATUS, PRODUCT_STATUS_CREATE, STORE_STATUS_CREATE} from "../../../config/enum";
import useAdminPhoneTypeModify from "../../../lib/api/useAdminPhoneTypeModify";

const ProductModify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let {product} = location.state;
  const [adminPhoneTypeModify, adminPhoneTypeModifyLoading, requestAdminPhoneTypeModify] =
    useAdminPhoneTypeModify();

  const [productForm, setProductForm] = useState();


  const [error, setError] = useState({});

  useEffect(() => {
    if(product){
      if(product?.capacity?.includes('GB')){
        product.capacity = product?.capacity?.replace("GB", "")
        product.unit = "GB"
      }else if(product?.capacity?.includes('T')){
        product.capacity = product?.capacity?.replace("TB", "")
        product.unit = "TB"
      }
    }
    setProductForm({
      name: product?.name || "",
      color: product?.color || "",
      capacity: product?.capacity || 0,
      unit: product?.capacity?.includes('TB') ? "TB" : "GB",
      status: product?.status || "",
      monthlyPrice: product?.monthlyPrice || 0,
    });
  }, []);

  useEffect(() => {
    if (adminPhoneTypeModify && adminPhoneTypeModify.code === 0) {
      handleGoBack()
    }
  }, [adminPhoneTypeModify]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({...error, [key]: null});
    }
    setProductForm({...productForm, [key]: value});
  };

  const checkParams = (productForm) => {
    let pass = true;
    let newError = {...productForm};

    for (let key in productForm) {
      if (productForm[key] === null || productForm[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setError(newError);
    return pass;
  };

  const handleCreateMemberApplication = () => {
    if (checkParams(productForm)) {
      requestAdminPhoneTypeModify(
        {
          id: product?.id,
          name: productForm.name,
          color: productForm.color,
          status: productForm.status,
          monthlyPrice: productForm?.monthlyPrice,
          capacity: `${productForm.capacity}${productForm.unit}`,
        });
    }
  };

  return (
    <StoreModifyStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit"/>
        </IconButton>
        {`修改商品`}
      </Typography>
      <StoreInputCard color="primary" sx={{textAlign: "left"}}>
        <InputBlock>
          <InputLabelStyled color="primary" required>
            手機機型
          </InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            defaultValue={product?.name || ""}
            error={error.name}
            size="small"
            onChange={(e) => handleChangeParams("name", e.target.value)}
          />
          <FormHelperText error={error.name}>
            {error.name}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>顏色</InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            size="small"
            defaultValue={product?.color}
            error={error.color}
            onChange={(e) => handleChangeParams("color", e.target.value)}
          />
          <FormHelperText error={error.color}>
            {error.color}
          </FormHelperText>
        </InputBlock>
        <InputBlock>
          <InputLabelStyled required>月租費</InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            size="small"
            defaultValue={product?.monthlyPrice}
            error={error.monthlyPrice}
            onChange={(e) => handleChangeParams("monthlyPrice", e.target.value)}
          />
          <FormHelperText error={error.monthlyPrice}>
            {error.monthlyPrice}
          </FormHelperText>
        </InputBlock>
        <InputLabelStyled color="primary" required>
          容量
        </InputLabelStyled>
        <OutlinedBlock>
          <OutlinedInput
            fullWidth
            type="number"
            required
            defaultValue={product?.capacity.replace(/\D/g, '') || 0}
            error={error.capacity}
            endAdornment={<div position="end">
              <Select
                fullWidth
                displayEmpty
                required
                size="small"
                defaultValue={product?.capacity?.includes('TB') ? "TB" : "GB"}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: "none",
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: "none",
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: "none",
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "unset !important",
                  }
                }}
                onChange={(e) => handleChangeParams("unit", e.target.value)}
              >
                {PHONE_CAPACITY ? (
                  Array.from(PHONE_CAPACITY.keys()).map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {PHONE_CAPACITY.get(option)?.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={0}></MenuItem>
                )}
              </Select>
            </div>}
            size="small"
            onChange={(e) => handleChangeParams("capacity", e.target.value)}
          />
          <FormHelperText error={error.capacity}>
            {error.capacity}
          </FormHelperText>
        </OutlinedBlock>

        <InputBlock>
          <InputLabelStyled required>狀態</InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={product?.status || ""}
            error={error.status}
            onChange={(e) => handleChangeParams("status", e.target.value)}
          >
            {PRODUCT_STATUS_CREATE ? (
              Array.from(PRODUCT_STATUS_CREATE.keys()).map((option, index) => (
                <MenuItem key={index} value={option}>
                  {PRODUCT_STATUS_CREATE.get(option)?.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.status}>
            {error?.status}
          </FormHelperText>
        </InputBlock>

        <MemberApplicationControlBlock>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleGoBack}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleCreateMemberApplication}
          >
            送出
          </TextButton>
        </MemberApplicationControlBlock>
      </StoreInputCard>
    </StoreModifyStyled>
  );
};

export default ProductModify;
