import { useEffect, useState } from "react";
import useAdminStorefrontList from "../../lib/api/useAdminStorefrontList";
import {
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Box,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MemberManagementStyled } from "./styled";
import {
  ZoomiDataGrid,
  CustomToolbar,
  ZoomiPagination,
} from "../../components/DataGrid";
import { FilterBarStyled } from "../../components/FilterBar";
import { TextButton } from "../../components/Button";
import {
  ACTION_COUNT_OPTIONS,
  STORE_STATUS,
  STORE_STATUS_LIST,
} from "../../config/enum";
import useJobList from "../../lib/api/useJobList";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";
import {
  DialogContentActionStyled,
  ZoomiDialogStyled,
} from "../../components/Dialog";
import useAdminStoreModify from "../../lib/api/useAdminStoreModify";

const StoreManagement = () => {
  const [adminStoreModify, adminStoreModifyLoading, requestAdminStoreModify] =
    useAdminStoreModify();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [adminStorefronts, adminStorefrontsLoading, fetchAdminStorefront] =
    useAdminStorefrontList({
      page: PAGE_START,
      size: PAGE_SIZE,
    });
  const [filterParams, setFilterParams] = useState({
    page: PAGE_START,
    size: PAGE_SIZE,
    statusList: ["ENABLE", "DISABLE"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminStorefront(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  useEffect(() => {
    if (adminStoreModify) {
      fetchAdminStorefront(filterParams);
    }
  }, [adminStoreModify]);

  const handleAdminStoreDelete = (store) => {
    requestAdminStoreModify({
      id: store?.id,
      name: store?.name,
      address: store?.address,
      phone: store?.phone,
      businessHours: store?.businessHours,
      detail: store?.detail,
      status: "DELETE",
    });
    setShowConfirmDialog(false);
  };
  const handleSelectedMemberDetail = (member) => {
    navigate("/member-management/" + member.id, {
      state: { member },
    });
  };

  const handleSelectedMemberApplication = (store) => {
    navigate("/store-management/modify", {
      state: { store },
    });
  };

  const handleDeleteStore = (store, onOpen) => {
    setSelectedStore(store);
    onOpen();
  };

  const handleFilterParamsChange = (key, value) => {
    if (value === "ALL" || value[0] === "ALL") {
      value = ["ENABLE", "DISABLE"];
    }
    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdateMemberList = () => {
    fetchAdminStorefront(filterParams);
  };

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  const columns = [
    // { field: "id", headerName: "門市編號", flex: 0.5 },
    {
      field: "name",
      headerName: "門市名稱",
      flex: 1,
      type: "string",
    },
    {
      field: "address",
      headerName: "地址",
      flex: 2,
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 0.5,
      renderCell: (params) => <>{STORE_STATUS.get(params.row.status)?.label}</>,
    },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1.5,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "updateMillis",
      headerName: "更新時間",
      flex: 1.5,
      renderCell: (params) => (
        <div>
          {params.row.updateMillis
            ? new Date(params.row.updateMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1.5,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{
              marginRight: "10px",
            }}
            onClick={() => handleSelectedMemberApplication(params.row)}
          >
            編輯
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleDeleteStore(params.row, () => setShowConfirmDialog(true))
            }
          >
            刪除
          </TextButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <MemberManagementStyled>
        <Typography color="primary" variant="h3" align="left">
          門市管理
        </Typography>
        <FilterBarStyled>
          <TextField
            color="primary"
            id="outlined-basic"
            label="門市名稱搜尋"
            variant="outlined"
            size="small"
            onChange={(e) => handleFilterParamsChange("name", e.target.value)}
          />
          <TextField
            id="outlined-select-currency"
            select
            size="small"
            label="狀態"
            sx={{ minWidth: "10rem" }}
            defaultValue={STORE_STATUS_LIST.get("ALL").value}
            onChange={(e) =>
              handleFilterParamsChange("statusList", [e.target.value])
            }
          >
            {Array.from(STORE_STATUS_LIST.keys()).map((key) => (
              <MenuItem key={key} value={STORE_STATUS_LIST.get(key).value}>
                {STORE_STATUS_LIST.get(key).label}
              </MenuItem>
            ))}
          </TextField>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleUpdateMemberList()}
          >
            查詢
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginLeft: "auto" }}
            onClick={() => handleSelectedMemberApplication()}
          >
            新增
          </TextButton>
        </FilterBarStyled>

        {adminStorefronts ? (
          <ZoomiDataGrid
            id
            rows={
              adminStorefronts
                ? adminStorefronts.storefronts.filter(
                    (el) => el.status !== "DELETE"
                  )
                : []
            }
            columns={columns}
            loading={adminStorefrontsLoading}
            disableRowSelectionOnClick
            disableColumnFilter
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 400px)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ZoomiPagination
          onChange={handleSelectedPage}
          count={adminStorefronts?.totalPage}
        />
      </MemberManagementStyled>
      <ZoomiDialogStyled open={showConfirmDialog}>
        <Typography color="primary" variant="h4">
          {"刪除門市"}
        </Typography>
        <DialogContent>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            資料刪除後不可復原，請問是否刪除此門市？
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            門市名稱: {selectedStore?.name}
          </Typography>
        </DialogContent>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowConfirmDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleAdminStoreDelete(selectedStore)}
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default StoreManagement;
