import { useCallback, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "./requestHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { MESSAGE_TYPE } from "../../config/enum";
import useErrorHandler from "./useErrorHandler";
import { useSnackbar } from "notistack";

const useAdminPhoneTypeCreate = () => {
  const [handleError] = useErrorHandler();
  const [adminPhoneTypeCreate, setAdminPhoneTypeCreate] = useState(null);
  const [adminPhoneTypeCreateLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestAdminPhoneTypeCreate = useCallback(async (param) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/phone-type/create",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(param),
        }
      );
      if (res?.message === "Success") {
        setAdminPhoneTypeCreate(res);
        enqueueSnackbar("成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setAdminPhoneTypeCreate(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [
      adminPhoneTypeCreate,
      adminPhoneTypeCreateLoading,
      requestAdminPhoneTypeCreate,
    ],
    [
      adminPhoneTypeCreate,
      adminPhoneTypeCreateLoading,
      requestAdminPhoneTypeCreate,
    ]
  );
};

export default useAdminPhoneTypeCreate;
