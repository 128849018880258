import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {StoreModifyStyled, StoreInputCard} from "./styled";
import {
  Typography,
  OutlinedInput,
  IconButton,
  MenuItem,
  FormHelperText,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {TextButton} from "../../../components/Button";
import {
  FlexBlock, FlexInsideBlock,
  InputBlock,
  InputLabelStyled,
  MemberApplicationControlBlock, OutlinedBlock,
} from "../../../components/Card";
import useJobList from "../../../lib/api/useJobList";
import useCityTownList from "../../../lib/api/useCityTownList";
import usePhoneTypeList from "../../../lib/api/usePhoneTypeList";
import useStorefrontList from "../../../lib/api/useStorefrontList";
import useContractCreate from "../../../lib/api/useContractCreate";
import {calcTimestampUTC, formatMoney} from "../../../lib/tools";
import {CONTRACT_DURING_MONTH} from "../../../config/setting";
import {STORE_STATUS, STORE_STATUS_CREATE} from "../../../config/enum";
import useAdminStoreModify from "../../../lib/api/useAdminStoreModify";
import useAdminStoreCreate from "../../../lib/api/useAdminStoreCreate";

const StoreModify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let {store} = location.state;
  const [cityTownList, cityTownListLoading, fetchCityTownList] = useCityTownList();
  const [jobList, jobListLoading, fetchJobList] = useJobList();
  const [phoneTypeList, phoneTypeLoading, fetchPhoneTypeList] =
    usePhoneTypeList();
  const [storefronts, storefrontsLoading, fetchStorefront] =
    useStorefrontList();
  const [adminStoreModify, adminStoreModifyLoading, requestAdminStoreModify] =
    useAdminStoreModify();


  const [adminStoreCreate, adminStoreCreateLoading, requestAdminStoreCreate] =
    useAdminStoreCreate();
  const [
    contractCreateLoading,
    contractParams,
    setContractParams,
    contractCreateResult,
    postContractCreate,
  ] = useContractCreate();

  const [selectedPhoneType, setSelectedPhoneType] = useState({
    name: null,
    capacity: null,
    color: null,
  });

  const [selectedCityTown, setSelectedCityTown] = useState({
    id: null,
    cityName: null,
    postalCode: null,
    townName: null,
  });
  const [storeForm, setStoreForm] = useState({
    name: null,
    address: null,
    status: null,
  });
  const [storeModifyStatus, setStoreModifyStatus] = useState(null);
  const [cityOptions, setCityOptions] = useState(null);
  const [townOptions, setTownOptions] = useState(null);
  const [phoneNameList, setPhoneNameList] = useState(null);
  const [phoneCapacityList, setPhoneCapacityList] = useState(null);
  const [phoneColorList, setPhoneColorList] = useState(null);
  //     {
  //   "contractName": "<string>",
  //   "contractStartMillis": "<long>",
  //   "discountAmount": "<number>",
  //   "monthlyPrice": "<number>",
  //   "phoneImei": "<string>",
  //   "phoneTypeId": "<long>",
  //   "pickUpMillis": "<long>",
  //   "storefrontId": "<long>",
  //   "userId": "<long>",
  //   "durationMonth": "<integer>",
  //   "detail": "<string>"
  // }

  const [error, setError] = useState({});

  useEffect(() => {
    if (!store) {
      setStoreModifyStatus("create");
    }else {
      fetchCityTownList();
      setStoreForm({
        ...store
      });
    }
  }, []);

  useEffect(() => {
    if (adminStoreModify && adminStoreModify.code === 0) {
      handleGoBack()
    }
  }, [adminStoreModify]);

  useEffect(() => {
    if (adminStoreCreate && adminStoreCreate.code === 0) {
      handleGoBack()
    }
  }, [adminStoreCreate]);

  useEffect(() => {
  }, [contractParams]);

  useEffect(() => {
    initPhoneSelectedOptions();
  }, [phoneTypeList]);

  useEffect(() => {
    initCityTownSelectedOptions();
  }, [cityTownList]);

  useEffect(() => {
    console.log(storeForm.cityName)
    getCityTownOptions();
  }, [storeForm.cityName]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChangeParams = (key, value) => {
    if (error[key]) {
      setError({...error, [key]: null});
    }
    setStoreForm({...storeForm, [key]: value});
    // setContractParams({...contractParams, [key]: value});
  };

  const checkParams = (storeForm) => {
    console.log(storeForm)
    let pass = true;
    let newError = {...storeForm};

    for (let key in storeForm) {
      if ((storeForm[key] === null || storeForm[key] === "") && key !== "updateMillis") {
        pass = false;
        newError[key] = "此欄位為必填";
      } else {
        newError[key] = null;
      }
    }
    setError(newError);
    return pass;
  };

  const handleCreateMemberApplication = () => {
    if (checkParams(storeForm)) {
      if (storeModifyStatus === "create") {
        requestAdminStoreCreate(
          {
            ...storeForm,
          });
      } else {
        requestAdminStoreModify(
          {
            ...storeForm,
          });
      }
    }
  };

  //  取得手機選項
  const getPhoneOptionsList = (key, list) => {
    let options = {};
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!options[element[key]]) {
        options[element[key]] = true;
      }
    }
    return Object.keys(options);
  };

  const initPhoneSelectedOptions = () => {
    if (phoneTypeList) {
      setSelectedPhoneType({...selectedPhoneType});
      const nameList = getPhoneOptionsList("name", phoneTypeList);
      setPhoneNameList(nameList);
    }
  };

  const getCityTownOptions = () => {
    if (cityTownList) {
      setCityOptions([...new Set(cityTownList.map((item) => item.cityName))]);
      setTownOptions([...new Set(cityTownList.filter((el) => storeForm.cityName === el.cityName).map((item) => item.townName))]);
    }
  }

  const initCityTownSelectedOptions = () => {
    if (cityTownList) {
      setSelectedCityTown({...selectedCityTown});
      const nameList = getCityTownOptions();
      console.log(nameList)
      // setPhoneNameList(nameList);
    }
  }

  const handleSelectPhoneTye = (type, value) => {
    if (type === "name") {
      const newList = phoneTypeList.filter((item) => item.name === value);
      const colorOptionList = getPhoneOptionsList("color", newList);
      setPhoneColorList(colorOptionList);
      setPhoneCapacityList(null);
      setSelectedPhoneType({
        ...selectedPhoneType,
        name: value,
        capacity: null,
        color: null,
      });
    } else if (type === "color") {
      const newList = phoneTypeList.filter(
        (item) => item.color === value && item.name === selectedPhoneType.name
      );
      const capacityOptionList = getPhoneOptionsList("capacity", newList);

      // 加上月租費的選項
      for (let i = 0; i < capacityOptionList.length; i++) {
        const element = capacityOptionList[i];
        const monthlyPrice = newList.find(
          (item) => item.capacity === element
        ).monthlyPrice;
        capacityOptionList[i] = {
          value: element,
          label: `${element}/ 月租 ${formatMoney(monthlyPrice)}`,
        };
      }

      setPhoneCapacityList(capacityOptionList);
      setSelectedPhoneType({
        ...selectedPhoneType,
        list: newList,
        color: value,
        capacity: null,
      });
    } else if (type === "capacity") {
      const newList = phoneTypeList.filter(
        (item) =>
          item.capacity === value &&
          item.name === selectedPhoneType.name &&
          item.color === selectedPhoneType.color
      );
      //  理論上只會有一筆
      setSelectedPhoneType({
        ...selectedPhoneType,
        capacity: value,
      });
      setContractParams({
        ...contractParams,
        phoneTypeId: newList[0].id,
        monthlyPrice: newList[0].monthlyPrice,
      });
    }
  };

  return (
    <StoreModifyStyled>
      <Typography color="primary" variant="h3" align="left">
        <IconButton onClick={handleGoBack} aria-label="back" size="normal">
          <ArrowBackIosIcon fontSize="inherit"/>
        </IconButton>
        {`${storeModifyStatus ? "新增" : "修改"}門市`}
      </Typography>
      <StoreInputCard color="primary" sx={{textAlign: "left"}}>
        {/*<InputBlock>*/}
        {/*  <InputLabelStyled color="primary" required>*/}
        {/*    門市編號*/}
        {/*  </InputLabelStyled>*/}
        {/*  <OutlinedInput*/}
        {/*    fullWidth*/}
        {/*    type="text"*/}
        {/*    required*/}
        {/*    defaultValue={store?.id || ""}*/}
        {/*    size="small"*/}
        {/*    onChange={(e) => handleChangeParams("id", e.target.value)}*/}
        {/*  />*/}
        {/*</InputBlock>*/}
        <InputBlock>
          <InputLabelStyled required>門市名稱</InputLabelStyled>
          <OutlinedInput
            fullWidth
            type="text"
            required
            size="small"
            defaultValue={store?.name}
            error={error.name}
            onChange={(e) => handleChangeParams("name", e.target.value)}
          />
          <FormHelperText error={error.name}>
            {error?.name}
          </FormHelperText>
        </InputBlock>
        <InputLabelStyled color="primary" required>
          地址
        </InputLabelStyled>
        <OutlinedBlock>
          <OutlinedInput
            fullWidth
            type="text"
            required
            defaultValue={store?.address || ""}
            error={error.address}
            size="small"
            onChange={(e) => handleChangeParams("address", e.target.value)}
          />
          <FormHelperText error={error.address}>
            {error?.address}
          </FormHelperText>
        </OutlinedBlock>

        <InputBlock>
          <InputLabelStyled required>營業狀態</InputLabelStyled>
          <Select
            fullWidth
            displayEmpty
            required
            size="small"
            defaultValue={store?.status || ""}
            error={error.status}
            onChange={(e) => handleChangeParams("status", e.target.value)}
          >
            {STORE_STATUS_CREATE ? (
              Array.from(STORE_STATUS_CREATE.keys()).map((option, index) => (
                <MenuItem key={index} value={option}>
                  {STORE_STATUS_CREATE.get(option)?.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}></MenuItem>
            )}
          </Select>
          <FormHelperText error={error.status}>
            {error?.status}
          </FormHelperText>
        </InputBlock>

        <MemberApplicationControlBlock>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleGoBack}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{}}
            onClick={handleCreateMemberApplication}
          >
            送出
          </TextButton>
        </MemberApplicationControlBlock>
      </StoreInputCard>
    </StoreModifyStyled>
  );
};

export default StoreModify;
