import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useAdminStorefront = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [adminStorefronts, setAdminStorefronts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStorefront = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/storefront/list",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        let adminStorefronts = res.content;
        setAdminStorefronts(adminStorefronts);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setAdminStorefronts(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [adminStorefronts, isLoading, fetchStorefront],
    [adminStorefronts, isLoading, fetchStorefront]
  );
};

export default useAdminStorefront;
