import { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  InputAdornment,
  CardActions,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import {
  InformationCardStyled,
  InputBlock,
  InputLabelStyled,
} from "../../components/Card";
import {
  InformationBlockStyled,
  InformationBlockTitleStyled,
  InformationBlockContentStyled,
} from "../../components/Card";
import { TextButton } from "../../components/Button";
import {
  ZoomiDialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentActionStyled,
} from "../../components/Dialog";
import { handleChangeParams } from "../../lib/tools";
import useAdminList from "../../lib/api/useAdminList";
import { LOCAL_STORAGE_EMAIL, ROLE_TYPE_OPTIONS } from "../../config/enum";
import { PAGE_SIZE, PAGE_START } from "../../config/setting";
import useAdminModifySelf from "../../lib/api/useAdminModifySelf";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UCInformationBlockStyled = styled(InformationBlockStyled)`
  width: 30rem;
`;

const UserCenter = () => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [adminList, adminListLoading, fetchAdminList] = useAdminList();
  const [adminModifySelf, adminModifySelfLoading, requestAdminModifySelf] =
    useAdminModifySelf();
  const [adminInfo, setAdminInfo] = useState({});
  const [passwordSetting, setPasswordSetting] = useState({
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const email = localStorage.getItem(LOCAL_STORAGE_EMAIL);

  useEffect(() => {
    fetchAdminList({
      email: email,
      page: PAGE_START,
      size: PAGE_SIZE,
    });
  }, []);

  useEffect(() => {
    if (adminList) {
      setAdminInfo(adminList.admins[0]);
    }
  }, [adminList]);

  const handleConfirmEdit = (onClose) => {
    if (checkRequireParams(passwordSetting, setError)) {
      requestAdminModifySelf({
        password: passwordSetting.newPassword,
      });
      onClose();
    }
  };

  const checkRequireParams = (params, setError) => {
    let pass = true;
    let newError = {};

    for (let key in params) {
      if (params[key] === null || params[key] === "") {
        pass = false;
        newError[key] = "此欄位為必填";
      }

      if (key === "newPasswordConfirm") {
        if (params[key] !== params["newPassword"]) {
          pass = false;
          newError[key] = "密碼不相符";
        }
      }
    }
    setError(newError);
    return pass;
  };

  const handleClickShowPassword = (type) => {
    if (type === "newPassword") {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else if (type === "newPasswordConfirm") {
      setShowPassword({
        ...showPassword,
        newPasswordConfirm: !showPassword.newPasswordConfirm,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        個人中心
      </Typography>
      <InformationCardStyled
        sx={{
          margin: "0.4rem 0",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
        variant="outlined"
        color="primary"
      >
        <UCInformationBlockStyled>
          <InformationBlockTitleStyled>使用者名稱</InformationBlockTitleStyled>
          <InformationBlockContentStyled>
            {adminInfo?.name}
          </InformationBlockContentStyled>
        </UCInformationBlockStyled>
        <UCInformationBlockStyled>
          <InformationBlockTitleStyled>帳號</InformationBlockTitleStyled>
          <InformationBlockContentStyled>
            {adminInfo?.email}
          </InformationBlockContentStyled>
        </UCInformationBlockStyled>
        <UCInformationBlockStyled>
          <InformationBlockTitleStyled>角色</InformationBlockTitleStyled>
          <InformationBlockContentStyled>
            {ROLE_TYPE_OPTIONS.get(adminInfo?.role)?.label}
          </InformationBlockContentStyled>
        </UCInformationBlockStyled>
        <UCInformationBlockStyled>
          <InformationBlockTitleStyled
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            登入密碼
            <TextButton
              sx={{ minWidth: "unset", padding: "0 0.5rem" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowEditDialog(true)}
            >
              修改
            </TextButton>
          </InformationBlockTitleStyled>
          <InformationBlockContentStyled></InformationBlockContentStyled>
        </UCInformationBlockStyled>
      </InformationCardStyled>

      <ZoomiDialogStyled open={showEditDialog}>
        <DialogTitleStyled color="primary" variant="h4">
          修改密碼
        </DialogTitleStyled>
        <DialogContentStyled>
          <InputBlock>
            <InputLabelStyled color="primary" required>
              輸入新密碼
            </InputLabelStyled>
            <OutlinedInput
              fullWidth
              type={showPassword.password ? "text" : "password"}
              required
              defaultValue={passwordSetting?.newPassword || ""}
              size="small"
              error={!!error.newPassword}
              onChange={(e) =>
                handleChangeParams(
                  "newPassword",
                  e.target.value,
                  error,
                  setError,
                  passwordSetting,
                  setPasswordSetting
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("newPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={!!error.newPassword}>
              {error?.newPassword}
            </FormHelperText>
          </InputBlock>
          <InputBlock>
            <InputLabelStyled color="primary" required>
              再次輸入新密碼
            </InputLabelStyled>
            <OutlinedInput
              fullWidth
              type={showPassword.newPasswordConfirm ? "text" : "password"}
              required
              defaultValue={passwordSetting?.newPasswordConfirm || ""}
              size="small"
              error={!!error.newPasswordConfirm}
              onChange={(e) =>
                handleChangeParams(
                  "newPasswordConfirm",
                  e.target.value,
                  error,
                  setError,
                  passwordSetting,
                  setPasswordSetting
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword("newPasswordConfirm")
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.newPasswordConfirm ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={!!error.newPasswordConfirm}>
              {error?.newPasswordConfirm}
            </FormHelperText>
          </InputBlock>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setShowEditDialog(false);
              setPasswordSetting({
                newPassword: "",
                newPasswordConfirm: "",
              }); // clear password setting
            }}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleConfirmEdit(() => setShowEditDialog(false))}
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default UserCenter;
