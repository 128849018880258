import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import useMemberList from "../../lib/api/useMemberList";
import {
  Table,
  TableContainer,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  Box,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MemberManagementStyled } from "./styled";
import {
  ZoomiDataGrid,
  CustomToolbar,
  ZoomiPagination,
} from "../../components/DataGrid";
import { FilterBarStyled } from "../../components/FilterBar";
import { TextButton } from "../../components/Button";
import {
  GENDER_OPTIONS,
  AGE_OPTIONS,
  OCCUPATION_OPTIONS,
  ACTION_COUNT_OPTIONS,
} from "../../config/enum";
import useJobList from "../../lib/api/useJobList";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";

// const GENDER_OPTIONS = [
//   {
//     value: "ALL",
//     label: "全部",
//   },
//   {
//     value: "MALE",
//     label: "男",
//   },
//   {
//     value: "FEMALE",
//     label: "女",
//   },
// ];

const MemberManagement = () => {
  const [memberList, isLoading, fetchMemberList] = useMemberList();
  const [filterParams, setFilterParams] = useState({
    page: PAGE_START,
    size: PAGE_SIZE,
  });

  const [jobList, jobListLoading, fetchJobList] = useJobList();

  const navigate = useNavigate();

  useEffect(() => {
    fetchJobList();
  }, []);

  useEffect(() => {
    fetchMemberList(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  const handleSelectedMemberDetail = (member) => {
    navigate("/member-management/" + member.id, {
      state: { member },
    });
  };

  const handleSelectedMemberApplication = (member) => {
    navigate("/member-management/application", {
      state: { member },
    });
  };

  const handleFilterParamsChange = (key, value) => {
    if (key === "endAge") {
      setFilterParams({
        ...filterParams,
        startAge: AGE_OPTIONS.get(value).startAge,
        endAge: AGE_OPTIONS.get(value).endAge,
      });
      return;
    }

    if (key === "activeContractCountMin") {
      setFilterParams({
        ...filterParams,
        activeContractCountMin:
          ACTION_COUNT_OPTIONS.get(value).activeContractCountMin,
        activeContractCountMax:
          ACTION_COUNT_OPTIONS.get(value).activeContractCountMax,
      });
      return;
    }
    if (value === "ALL" || value[0] === "ALL") {
      value = null;
    }

    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdateMemberList = () => {
    fetchMemberList(filterParams);
  };

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 50 },
    {
      field: "email",
      headerName: "帳號",
      flex: 2,
      minWidth: 250,
      type: "string",
    },
    {
      field: "phoneNumber",
      headerName: "手機號碼",
      minWidth: 120,
      flex: 1,
    },
    { field: "name", headerName: "姓名", minWidth: 80, flex: 0.5 },
    {
      field: "gender",
      headerName: "性別",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => (
        <>{GENDER_OPTIONS.get(params.row.gender)?.label}</>
      ),
    },
    { field: "birthdayStr", headerName: "出生日期", minWidth: 100, flex: 1 },
    { field: "jobName", headerName: "職業", minWidth: 150, flex: 1.5 },
    {
      field: "createMillis",
      headerName: "註冊時間",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "activeContractCount",
      headerName: "進行合約",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{
              marginRight: "10px",
            }}
            disabled={params.row.kycStatus !== "ACCEPTED"}
            onClick={() => handleSelectedMemberApplication(params.row)}
          >
            申辦
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleSelectedMemberDetail(params.row)}
          >
            查看
          </TextButton>
        </>
      ),
    },
  ];

  return (
    <MemberManagementStyled>
      <Typography color="primary" variant="h3" align="left">
        會員管理
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="帳號搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("email", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="姓名搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("name", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          color="primary"
          select
          label="性別"
          sx={{ minWidth: "8rem" }}
          size="small"
          defaultValue={GENDER_OPTIONS.get("ALL").value}
          onChange={(e) => handleFilterParamsChange("gender", e.target.value)}
        >
          {Array.from(GENDER_OPTIONS.keys()).map((key) => (
            <MenuItem key={key} value={GENDER_OPTIONS.get(key).value}>
              {GENDER_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="年齡"
          size="small"
          sx={{ minWidth: "8rem" }}
          defaultValue={AGE_OPTIONS.get("ALL").value}
          onChange={(e) => handleFilterParamsChange("endAge", e.target.value)}
        >
          {Array.from(AGE_OPTIONS.keys()).map((key) => (
            <MenuItem key={key} value={AGE_OPTIONS.get(key).value}>
              {AGE_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          size="small"
          label="職業"
          sx={{ minWidth: "8rem" }}
          defaultValue={"ALL"}
          onChange={(e) => handleFilterParamsChange("jobIds", [e.target.value])}
        >
          {jobList?.map((obj, index) => (
            <MenuItem key={index} value={obj.id}>
              {obj.chName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          size="small"
          label="進行合約次數"
          sx={{ minWidth: "8rem" }}
          defaultValue={ACTION_COUNT_OPTIONS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("activeContractCountMin", e.target.value)
          }
        >
          {Array.from(ACTION_COUNT_OPTIONS.keys()).map((key) => (
            <MenuItem key={key} value={ACTION_COUNT_OPTIONS.get(key).value}>
              {ACTION_COUNT_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleUpdateMemberList()}
        >
          查詢
        </TextButton>
      </FilterBarStyled>

      {memberList ? (
        <div>
          <ZoomiDataGrid
            id
            rows={memberList ? memberList.userDtos : []}
            columns={columns}
            loading={isLoading}
            slots={{
              toolbar: CustomToolbar,
            }}
            autoHeight
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            disableRowSelectionOnClick
            disableColumnFilter
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        onChange={handleSelectedPage}
        count={memberList?.totalPage}
      />
    </MemberManagementStyled>
  );
};

export default MemberManagement;
