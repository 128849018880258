import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "../api/requestHandler";
import { AuthContext } from "./../context/authContext";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import useErrorHandler from "./useErrorHandler";

const useAdminPhoneTypeList = () => {
  const [handleError] = useErrorHandler();
  const authContext = useContext(AuthContext);
  const [adminPhoneTypeList, setAdminPhoneTypeList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAdminPhoneTypeList = useCallback(async (params) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/phone-type/list",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setAdminPhoneTypeList(res.content);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setAdminPhoneTypeList(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [adminPhoneTypeList, isLoading, fetchAdminPhoneTypeList],
    [adminPhoneTypeList, isLoading, fetchAdminPhoneTypeList]
  );
};

export default useAdminPhoneTypeList;
