import { useCallback, useMemo, useState, useContext } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";
import { MESSAGE_TYPE } from "../../config/enum";
import { useSnackbar } from "notistack";
import { AuthContext } from "./../context/authContext";
import useErrorHandler from "./useErrorHandler";

const useContractCreate = () => {
  const [handleError] = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    userId: null,
    contractName: null,
    phoneTypeId: null,
    phoneImei: null,
    monthlyPrice: null,
    discountAmount: null,
    contractStartMillis: null,
    storefrontId: null,
    //pickUpMillis: null,
  });

  const [contractCreateResult, setContractCreateResult] = useState(null);

  const postContractCreate = useCallback(async (params, callbackFunction) => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/admin/contract/create",
        API_METHOD.POST,
        auth,
        {
          body: JSON.stringify(params),
        }
      );
      if (res.message === "Success") {
        setContractCreateResult(res.content);
        enqueueSnackbar("送出成功", { variant: MESSAGE_TYPE.SUCCESS });
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (callbackFunction) {
        callbackFunction();
      }
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [
      isLoading,
      params,
      setParams,
      contractCreateResult,
      postContractCreate,
    ],
    [isLoading, params, setParams, contractCreateResult, postContractCreate]
  );
};

export default useContractCreate;
