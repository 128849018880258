import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./../lib/context/authContext";
import { useEffect, useContext } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Drawer, IconButton, Divider, Typography, Button } from "@mui/material";
import { useState } from "react";
import { HeaderStyled, ContainerStyled, RootLayoutStyled } from "./styled";
import LinkMenu from "../components/Menu";
import {
  drawerWidth,
  headerHeight,
  HeaderTitleStyled,
  ContentStyled,
} from "./styled";
import { LOCAL_STORAGE_AUTH_KEY } from "./../config/enum";

const RootLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

    if (!auth) {
      navigate("/login");
    }
  }, [auth.isLoggedIn]);

  const handleLogout = async () => {
    try {
      const res = await auth.logoutHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootLayoutStyled>
      {/* <Header />  // Header component */}
      <HeaderStyled open={openDrawer}>
        <HeaderTitleStyled>
          <IconButton color="secondary" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h4">租咪ZOOMI</Typography>
        </HeaderTitleStyled>
        <Button
          disableElevation
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleLogout}
        >
          登出
        </Button>
      </HeaderStyled>
      <ContainerStyled>
        <div className="inner">
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                marginTop: headerHeight,
                boxSizing: "border-box",
                backgroundColor: "background.secondary",
              },
            }}
            open={openDrawer}
            variant="persistent"
            anchor="left"
          >
            <LinkMenu />
          </Drawer>
          <ContentStyled open={openDrawer}>
            <div className="container">{<Outlet />}</div>
            {/* <Footer /> // Footer component */}
          </ContentStyled>
        </div>
      </ContainerStyled>
    </RootLayoutStyled>
  );
};

export default RootLayout;
