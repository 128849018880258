import { useState, createContext, useEffect } from "react";
import { API_URL, API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "../api/useErrorHandler";
import {
  LOCAL_STORAGE_AUTH_KEY,
  LOCAL_STORAGE_EMAIL,
  LOCAL_STORAGE_ROLE,
} from "./../../config/enum";

export const AuthContext = createContext({
  isLoggedIn: false,
  auth: "",
  logoutHandler: () => {},
  loginHandler: (username, password) => {},
  tokenExpiredHandler: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [auth, setAuth] = useState(null);
  const [role, setRole] = useState(null);
  const [email, setEmail] = useState(null);
  const [handleError] = useErrorHandler();

  useEffect(() => {
    const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    const email = localStorage.getItem(LOCAL_STORAGE_EMAIL);
    const role = localStorage.getItem(LOCAL_STORAGE_ROLE);
    if (auth && email && role) {
      setAuth(auth);
      setRole(role);
      setEmail(email);
      setIsLoggedIn(true);
    }
  }, []);

  const tokenExpiredHandler = () => {
    setAuth(null);
    setRole(null);
    setEmail(null);
    setIsLoggedIn(false);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    localStorage.removeItem(LOCAL_STORAGE_EMAIL);
    localStorage.removeItem(LOCAL_STORAGE_ROLE);
  };

  const logoutHandler = async () => {
    if (!auth) return;
    try {
      const res = await requestHandler(API_URL.LOGOUT, API_METHOD.POST, auth);

      if (res.message === "Success") {
        setAuth(null);
        setRole(null);
        setEmail(null);
        setIsLoggedIn(false);
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        localStorage.removeItem(LOCAL_STORAGE_EMAIL);
        localStorage.removeItem(LOCAL_STORAGE_ROLE);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      console.error("Error logout Handler:", error);
    }
  };

  const loginHandler = async (username, password) => {
    try {
      const res = await requestHandler(API_URL.LOGIN, API_METHOD.POST, null, {
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });

      if (res.message === "Success") {
        setAuth(res.content.loginToken);
        setRole(res.content.role);
        setEmail(username);
        setIsLoggedIn(true);
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, res.content.loginToken);
        localStorage.setItem(LOCAL_STORAGE_EMAIL, username);
        localStorage.setItem(LOCAL_STORAGE_ROLE, res.content.role);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      console.error("Error login Handler:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        auth,
        role,
        logoutHandler,
        loginHandler,
        tokenExpiredHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
