import { styled } from "@mui/material/styles";

export const MemberReviewStyled = styled("div")``;

export const ItemBlockStyled = styled("div")`
  display: inline-block;
`;

export const MemberReviewControlStyled = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2rem;
  padding: 2rem;
`;

export const ImageStyled = styled("img")`
  width: 30rem;
  border-radius: 0.3rem;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
`;

export const MemberReviewControlBlock = styled("div")`
  padding: 2rem;
`;
