import { useCallback, useContext, useMemo, useState } from "react";
import { API_METHOD, requestHandler } from "../api/requestHandler";
import useErrorHandler from "./useErrorHandler";
import { LOCAL_STORAGE_AUTH_KEY } from "./../../config/enum";

const useStorefront = () => {
  const [handleError] = useErrorHandler();
  const [storefronts, setStorefronts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStorefront = useCallback(async () => {
    try {
      setIsLoading(true);

      const auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

      const res = await requestHandler(
        "/zoomi/constant/storefront/list",
        API_METHOD.GET,
        auth
      );
      if (res.message === "Success") {
        let storefronts = res.content.storefronts;
        setStorefronts(storefronts);
      } else {
        handleError(res?.code);
      }
    } catch (error) {
      setStorefronts(null);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => [storefronts, isLoading, fetchStorefront],
    [storefronts, isLoading, fetchStorefront]
  );
};

export default useStorefront;
